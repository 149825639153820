import React from "react";
import './TitleBanner.css';

/**
 * 
 * @param {string} backgroundImage: Link to the background image of the page's title banner
 * @param {string} titleBlurb: Optional string for a blurb above the title 
 * @param {string} title: Represents title of the current page, displayed on the title banner
 * @returns A React component that is a banner for the title of a given web page
 */
export default function TitleBanner({backgroundImage, titleBlurb, title}){
    
    return (
        <div className="titleBanner" style={{
            backgroundImage: `url('${backgroundImage}')`
        }}>
            <h6 className="bannerBlurb">{titleBlurb}</h6>
            <h1 className="bannerText">{title}</h1>
        </div>
    )
}