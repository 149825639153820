import React, {useState, useMemo} from "react";
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { useParams } from "react-router-dom";
import { getBlogByID } from "../../api/api";
import BlogTags from "../../components/blogTags/BlogTags";
import './SpecificBlogContent.css';
import TitleBanner from "../../components/titleBanner/TitleBanner";
import titleBackground from '../../assets/images/blogImages/title-background.jpg';

/**
 * 
 * @returns A page corresponding to a specific blog article
 */
export default function SpecificBlogContent(){

    // Obtains the blog ID based on the search parameters of the current path
    const { blogID } = useParams()

    const [blogTitle, setBlogTitle] = useState('')
    const [postDate, setPostDate] = useState('')
    const [blogTags, setBlogTags] = useState([])
    const [blogContent, setBlogContent] = useState([])

    /**
     * Performs GET request to get a specific Blog entry by ID
     */
    async function searchBlogByID(){
        const response = await getBlogByID(blogID)

        if(response.data && response.data.attributes){
            const responseData = response.data.attributes
            setBlogTitle(responseData.Title)
            setPostDate(responseData.PostDate)
            setBlogContent(responseData.BlogContent)
            parseBlogTags(responseData.BlogTags.data)
        }
        else{
            window.location.href=`/blog`
        }
    }

    /**
     * 
     * This function parses all blog tags given an Array of JSON objects, setting the result in "blogTags"
     * 
     * @param {Array[JSON]} tagDataJSON: Corresponds to an Array of JSON objects, in which each JSON object represents a tag (that the current blog is labeled under) 
     */
    function parseBlogTags(tagDataJSON){
        var allTags = []
        for(var i=0;i<tagDataJSON.length;i++){
            allTags.push(tagDataJSON[i].attributes.TagName)
        }
        setBlogTags(allTags)
    }

    useMemo(() => {
        searchBlogByID()
    }, [])

    useMemo(() => {
        document.title = `${blogTitle} - Blog`
    }, [blogTitle])

    return (
        <div className="specificBlogContent">
            <TitleBanner backgroundImage={titleBackground} title = "Our Blog"/>
            <div className="blogData">
                <h1 className="blogTitle">{blogTitle}</h1>
                <p className="postDate">Post Date: {postDate}</p>
                <p className="tagLabels">Tags:

                    {/**
                     * Iterates through all elements in "blogTags", outputting each tag (separated by commas)
                     */}
                    {
                        blogTags.map((tagName, index) => {
                            const tagLink = `/blog/tags/${tagName}`
                            return (
                                <a href={tagLink} key={tagName}> {tagName}
                                    {(index < blogTags.length - 1) && 
                                        <>
                                            ,
                                        </>
                                    }
                                </a>
                                
                            )
                        })
                    }

                </p>
            </div>

            {/**
             * Renders the blog content with Strapi's BlocksRenderer system rather than Markdown content
             */}
            <div className="blogText">
                <BlocksRenderer content={blogContent} />
            </div>

            <BlogTags/>
        </div>
    )
}