import React, {useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import './CapabilitiesPages.css';

import TitleBanner from "../../components/titleBanner/TitleBanner";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb";
import CapabilitiesBlock from "../../components/capabilitiesBlock/CapabilitiesBlock";
import QuoteBlock from "../../components/quote/QuoteBlock";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner";
import ServicesGrid from "../../components/servicesGrid/ServicesGrid";
import MiniCapabilitiesBlock from "../../components/capabilitiesBlock/MiniCapabilitiesBlock";
import { TextPosition } from "../../models/TextPosition";
import { ColorThemes } from "../../models/ColorThemes";
import { PageTypes } from "../../models/PageTypes";
import { getDisplayType } from "../../controllers/screen-controller.js";
import { getSpecificResource } from "../../api/api.js";
import titleBackground from "../../assets/images/gisImages/title-background.png";
import analyticsIcon from "../../assets/images/homeImages/analytics-icon.png";
import gisIcon from "../../assets/images/homeImages/gis-icon.png";
import servicesGridBackground from "../../assets/images/gisImages/services-grid-background.png";
import enterpriseService from "../../assets/images/gisImages/enterprise-service.png";
import planService from "../../assets/images/gisImages/plan-service.png";
import complianceService from "../../assets/images/gisImages/compliance-service.png";
import apisService from "../../assets/images/gisImages/apis-service.png";
import precisionService from "../../assets/images/gisImages/precision-service.png";
import conclusionBackground from "../../assets/images/gisImages/conclusion-background.png";

/**
 * 
 * @returns A page corresponding to the GIS page of the Niyam website, under the path "/gis"
 */
export default function GIS(){
    const navigate = useNavigate()
    const [currentDisplay, setCurrentDisplay] = useState()
    const [gisURL, setGisURL] = useState('')

    /**
     * Performs GET request to obtain relevant PDF sources for the current page
     */
    async function getRelevantResources(){
        setGisURL(await getSpecificResource('nit_gis.pdf'))
    }

    useMemo(() => {
        document.title = "GIS"

        setCurrentDisplay(getDisplayType())
        getRelevantResources()
    }, [])

    return (
        <div className="gisContainer">
            <TitleBanner backgroundImage={titleBackground} title="Geospatial Business Intelligence, Situational Awareness"/>
        
            <div className="factSheet">
                <TopicBlurb colorTheme={ColorThemes.Light} topic="ASSESS. PREPARE. MITIGATE." title="GET A GLOBAL PERSPECTIVE"/>
                <p className="factSheetText">
                    Using advanced spatial modeling software and highly-specialized tools and techniques, our GIS experts can collect, 
                    interpret and analyze geospatial data to reveal accurate, up-to-the-minute, mission-critical insights. 
                    By bringing together our extensive experience in queries, coding, visualization and big data, Niyam is uniquely qualified to design and deploy custom GIS solutions tailored to meet your specific planning, mitigation and risk assessment needs.
                </p>
                <button onClick={() => navigate(gisURL)}>
                    View Our GIS Factsheet
                </button>
            </div>
            <div className="gisCapabilities">
                <CapabilitiesBlock 
                    currentDisplay={currentDisplay}
                    icon={analyticsIcon}
                    topic="Risk Assessments" 
                    textBlocks={[
                        "Know your vulnerabilities: Get a clear, accurate picture of potential risks and hazards.",
                        "We are Risk Assessment experts, having helped design the Hazards U.S.-Multiple Hazards (Hazus-MH) software for FEMA, implemented by emergency management organizations from the U.S., Canada and around the globe"
                    ]}
                    textPosition={TextPosition.Right}
                    />

                <CapabilitiesBlock
                    currentDisplay={currentDisplay} 
                    icon={analyticsIcon}
                    topic="Mitigation and Preparedness" 
                    textBlocks={[
                        "Optimize your planning and mitigation efforts with real-time, on-the-ground insights.",
                        "We deliver highly-accurate, real-time analytics to drastically reduce response time and mitigate damage from both man-made and natural disasters"
                    ]}
                    textPosition={TextPosition.Left}
                    />

                <CapabilitiesBlock
                    currentDisplay={currentDisplay} 
                    icon={gisIcon}
                    topic="Custom GIS Applications" 
                    textBlocks={[
                        "Our complete solution for specialized risk management.",
                        'Our all-in-one Risk Management Toolkit combines GIS and big data with custom software, providing a tailored "pre-covery" solution for accidents and disasters'
                    ]}
                    textPosition={TextPosition.Right}
                    />
            </div>

            <ServicesGrid
                backgroundImage={servicesGridBackground}
                colorTheme={ColorThemes.Dark}
                topic="GIS ANALYTICS"
                title="Features & Benefits"
                serviceComponents={[
                    <MiniCapabilitiesBlock 
                        iconImage={enterpriseService} 
                        topic="GIS for Enterprise" 
                        text="Complete enterprise-wide GIS system design, capacity planning and optimization"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={planService} 
                        topic="Plan Confidently" 
                        text="Be ready for any scenario with custom risk assessments and mitigation/preparedness plans"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={complianceService}
                        topic="Quality Compliance" 
                        text="Geospatial visualization and analytics adhere to strict FGDC quality standards"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={apisService} 
                        topic="Open Source APIs" 
                        text="Simplify and organize structured and unstructured data using Natural Language Processing (NLP), custom algorithms and ontologies"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={precisionService}
                        topic="Real-Time Precision" 
                        text="Respond quickly to changing developments with precise, accurate, up-to-the-minute situational awareness planning"/>,
                ]}
                />

            <QuoteBlock
                colorTheme={ColorThemes.Light}
                quote="Keep doing the great job you always do! You are innovative and excellent at delivering. I look forward to continuing to work with you."
                author="Paul Huang"
                authorAffiliation="Deputy Assistant Administrator for Federal Insurance, FEMA"/>

            <ConclusionBanner
                backgroundImage={conclusionBackground}
                title="CRITICAL DECISIONS REQUIRE CRITICAL DETAILS"
                body="Let our GIS experts show you the big picture"
                buttonRef="/contact-us"
                buttonText="Request a consultation"/>

            <PartnersBanner pageType={PageTypes.GIS} />

        </div>
    )
}
