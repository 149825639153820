import React, {useState, useMemo} from "react";

import './ContactUs.css';
import TitleBanner from "../../components/titleBanner/TitleBanner";
import AddressComponent from "../../components/addressComponent/AddressComponent";
import titleBackground from "../../assets/images/contactUsImages/title-background.png";
import ContactInfoSection from "../../components/contactInfoSection/ContactInfoSection";
import { validateEmail } from "../../controllers/message-controller";
import { composeMessageRequest } from "../../api/api";

/**
 * 
 * @returns A page corresponding to the "Contact Us" page of the site, under the path "/contact-us"
 */
export default function ContactUs(){

    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [contactMe, setContactMe] = useState(false)
    const [sentMessage, setSentMessage] = useState(false)

    /**
     * 
     * This function checks if the inputted email is in a valid format with the help of "validateEmail()" from "message-controller.js"
     * 
     * @param {*} event: Represents the event of the input change
     * @param {string} inputtedEmail: Corresponds to the email typed into the input element 
     */
    function checkValidEmail(event, inputtedEmail){
        const trimmedInput = inputtedEmail.trim()
        setEmail(trimmedInput)
        if(!validateEmail(trimmedInput)){
            event.target.id = "invalidInput"
        }
        else{
            event.target.id = "validInput"
        }
    }

    /**
     * This function checks to make sure an input element is not empty. If an input element is empty (when trimmed), the HTML element is marked with an id of "invalidInput"
     * 
     * @param {*} event 
     * @param {string} inputtedText: Represents to an inputted text value
     * @param {Function} callback: Corresponds to a Callback function that determines what to do with the "inputtedText" value
     */
    function checkValidInput(event, inputtedText, callback){
        const trimmedInput = inputtedText.trim()
        callback(trimmedInput)
        if(trimmedInput.length === 0){
            event.target.id = "invalidInput"
        }
        else{
            event.target.id="validInput"
        }
    }

    /**
     * This function checks to see if all inputs are valid. If so, a message is send by performing a POST request to the Strapi backend at the path "/send-message" (see api.js)
     */
    async function sendMessage(){
        var canSend = true
        const messageInputs = document.getElementsByClassName('messageInput')
        
        for(var i=0;i<messageInputs.length;i++){
            if(messageInputs[i].value.trim().length === 0){
                messageInputs[i].id = "invalidInput"
                canSend = false
            }
        }

        if(canSend){
            const response = await composeMessageRequest(name, company, email, phone, message, contactMe)

            if(response.status === 200){
                setSentMessage(true)
            }
        }
    }

    useMemo(() => {
        document.title = "Contact Us"
    }, [])

    return (
        <div className="contactUsPage">
            <TitleBanner backgroundImage={titleBackground} title="CONTACT US"/>

            <div className="contactGridWrapper">    
                <div className="contactGrid">
                    <div className="contactComponent">
                        <h2 className="contactTitle">Send a Message</h2>
                        <form className="messageForm">
                            <input className="messageInput" type="text" placeholder="First & Last Name" onChange={(event) => checkValidInput(event, event.target.value, setName)}/>
                            <input className="messageInput" type="text" placeholder="Company/Agency" onChange={(event) => checkValidInput(event, event.target.value, setCompany)}/>
                            <input className="messageInput" type="text" placeholder="Email Address" onChange={(event) => checkValidEmail(event, event.target.value)}/>
                            <input className="messageInput" type="text" placeholder="Daytime Phone" onChange={(event) => checkValidInput(event, event.target.value, setPhone)}/>
                            <textarea placeholder="Add a Message" className="messageInput" onChange={(event) => checkValidInput(event, event.target.value, setMessage)}></textarea>
                            
                            <div className="messageContactDiv">
                                <input className="messageCheckBox" type="checkbox" onChange={(event) => setContactMe(event.target.checked)}/>
                                <label className="checkBoxLabel">Please have someone contact me to schedule a consultation.</label>
                            </div>
                        </form>
                        <button className="sendMessage" onClick={sendMessage}>
                            <p className="sendText">Send</p>
                        </button>
                        {sentMessage && 
                            <div className="messageSentDisplay">
                                <p>Your message has been sent.</p>
                            </div>
                        }
                    </div>
                    <div className="contactComponent">
                        <div className="contactInfoComponent">
                            <h2 className="contactTitle">Our Location</h2>
                            <div className="locationMap">
                                <iframe className="mapIFrame" title="companyAddress" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3106.758394371683!2d-77.29988592354911!3d38.86088714885667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64ea528718261%3A0x22555783d602f2af!2s10201%20Fairfax%20Blvd%2C%20Fairfax%2C%20VA%2022030!5e0!3m2!1sen!2sus!4v1721841301995!5m2!1sen!2sus" allowFullScreen="" loading="lazy"></iframe>
                            </div>
                            <div className="contactUsDiv">
                                <AddressComponent title="Headquarters" address1="880 Harrison Street SE" address2="Leesburg, VA 20175"/>
                                <AddressComponent title="Solution Development Center" address1="10201 Fairfax Blvd, Suite 224" address2="Fairfax, VA 22030"/>
                                <ContactInfoSection/>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}