import React, { useState, useMemo, useEffect } from "react";

import { getCertifications } from "../../api/api";
import { redirectToFragment } from "../../controllers/url-fragment-controller.js"; 
import { ColorThemes } from "../../models/ColorThemes";
import { PageTypes } from "../../models/PageTypes";
import TitleBanner from "../../components/titleBanner/TitleBanner";
import ContentQuoteBlock from "../../components/quote/ContentQuoteBlock";
import MiniCapabilitiesBlock from "../../components/capabilitiesBlock/MiniCapabilitiesBlock";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner";
import Certification from "../../components/certificationComponent/Certification";
import titleBackground from '../../assets/images/aboutUsImages/title-background.png';
import socialRespBackground from '../../assets/images/aboutUsImages/social-resp-background.png';
import conclusionBackground from '../../assets/images/aboutUsImages/conclusion-background.png';
import ContractVehicles from "../../components/contractVehicles/ContractVehicles.js";
import './AboutUs.css';
import CompanyValues from "../careers/CompanyValues.js";
import Timeline from "./Timeline.js";

/**
 * 
 * @returns A page corresponding to the "About Us" page
 */
export default function AboutUs(){

    const ourStoryBlock = [
        "Niyam IT was founded in 2007 by a group of consultants who shared a unique vision: a technology company steeped in orderly process, yet driven by passion and innovation. Over the following decade, we fine-tuned our craft and built an impressive track record of successful outcomes, securing our reputation as the go-to provider of smart, innovative solutions.",
        "Today, Niyam is at the forefront of the industry, leading the way in crafting mission-critical technologies for Emergency Preparedness & Response, Natural Resource Management, Law Enforcement & Justice, Health IT, and Global Citizen Services.",
        "Throughout our history of growth and change, one thing has always remained constant: our dedication to our clients. We'll continue pushing the limits of technology to reach new breakthroughs in the future, proving that process + passion really is a winning combination."
    ]
    const socialRespBlock = [
        "At Niyam IT, we are committed to being an ethical company as well as responsible members of our community. We believe in the inherent value of our environment and the importance of empowering our local community. We strive to give back proactively, which is why you'll find us in schools, nonprofits and charity events.",
        "We invest time and resources to support the local Loudoun County and Fairfax County schools, sponsoring programs that promote special education and STEM initiatives for kids. We work with leading universities to supply valuable internship opportunities, offering hands-on experiences to students and aspiring professionals. Outside of the classroom, we are frequent sponsors and active participants in workshops, conferences and hackathons, sharing our knowledge and skills to advance and collaborate with others within our fields.",
        "Niyam IT is committed to aiding others. We donate to charities that elevate human rights and community development, funding and supporting organizations that promote medical research and assistance for disadvantaged communities. Every member of our team is encouraged to help others through their own independent volunteering efforts."
    ]
    const [certifications, setCertifications] = useState([])

    async function obtainCertifications() {
        const certificationsJSON = await getCertifications()
        setCertifications(certificationsJSON.data)
    }

    useMemo(() => {
        document.title = "About Us"
        obtainCertifications()
    }, [])

    useEffect(() => {
        redirectToFragment()
    }, [])

    return (
        <div className="aboutUsContainer">
            <TitleBanner backgroundImage={titleBackground} titleBlurb="PASSION + PROCESS" title="About Us"/>

            <ContentQuoteBlock 
                colorTheme={ColorThemes.Light}
                title="Our Story"
                paragraphContent={ourStoryBlock}
                sideQuote="Our name says it all: 'Niyam' means orderly, systematic, grounded by rules. That's a huge part of who we are."
                quoteAuthor="Suman Biswas, Founder"
                authorAffiliation="Niyam IT"/>
            <Timeline/>
            <div className="companyValuesGrid">
                <TopicBlurb
                    colorTheme={ColorThemes.Light}
                    topic="COMPANY VALUES"
                    title="Our Bedrock Principles"/>

                <div className="corePrinciples">
                    <MiniCapabilitiesBlock
                        colorTheme={ColorThemes.Light}
                        iconFont={'\uE641'} 
                        topic="Integrity" 
                        text="Fairness and honesty are the guiding principles in all we do"/>
                    <MiniCapabilitiesBlock
                        colorTheme={ColorThemes.Light}
                        iconFont={'\uE618'} 
                        topic="Trust" 
                        text="Our deep experience puts us in the role of Trusted Advisor"/>
                    <MiniCapabilitiesBlock
                        colorTheme={ColorThemes.Light}
                        iconFont={'\uE606'} 
                        topic="Innovation" 
                        text="There’s always a Proof of Concept running in our lab"/>
                    <MiniCapabilitiesBlock
                        colorTheme={ColorThemes.Light}
                        iconFont={'\uE651'} 
                        topic="Professionalism" 
                        text="We're proud to work with dedicated, skilled professionals"/>
                    <MiniCapabilitiesBlock
                        colorTheme={ColorThemes.Light}
                        iconFont={'\uE607'} 
                        topic="Mission-Focused" 
                        text="Achieving mission success for our clients is our top priority"/>
                    <MiniCapabilitiesBlock
                        colorTheme={ColorThemes.Light}
                        iconFont={'\uE634'} 
                        topic="Client Service" 
                        text="We regularly earn the highest client satisfaction scores"/>
                </div>
            </div>

            <ContentQuoteBlock 
                urlFragment="socialresp"
                backgroundImage={socialRespBackground}
                colorTheme={ColorThemes.Dark}
                topic="GIVING BACK"
                title="Our Social Responsibilities"
                paragraphContent={socialRespBlock}
                sideQuote="A company with a heart."
                quoteAuthor="Neil Ghosh, CEO"
                authorAffiliation="SOS Children's Villages USA"
                />

            {/* <div className="employeeBenefits" id="careers">
                <div className="benefitsDescription">
                    <h2>WORK WITH US</h2>
                    <p className="benefitsStatement">Niyam IT prides itself on being a small, close-knit team of experts. Around here, you have the freedom to work how you want, with opportunities to offer new ideas, expand your skillset and make your mark on the IT industry.</p>
                    <h3>We offer a generous benefit package to all employees, including:</h3>
                </div> */}
            <CompanyValues/>
            <ConclusionBanner
                backgroundImage={conclusionBackground}
                title="Our team of skilled professionals are driven by a passion to innovate."
                buttonRef="/our-team"
                buttonText="Meet our team"/>

            <div className="ourClients">
                <p>Our Clients</p>
                <PartnersBanner pageType={PageTypes.OurClients}/>
                <p>Our technology partners add flexibility to the services we offer</p>
                <PartnersBanner pageType={PageTypes.OurVendors}/>
            </div>
            

            <div className="professionalCertifications">
                <div className="certificationsContent" id="certifications">
                    <h2 className="certificationsTitle">Professional Certifications</h2>
                    <div className="certificationDetails">
                        {certifications && certifications.map((currentCertification) => {
                            const certificationImages = currentCertification.attributes.CertificationImages.data 
                            return (
                                <Certification
                                    key={currentCertification.id}
                                    certificationName={currentCertification.attributes.CertificationName}
                                    certificationImages={certificationImages}
                                    certificationText={currentCertification.attributes.CertificationDescription}
                                    />
                            )
                        })}
                    </div>
                </div>
            </div>
        
            <ContractVehicles/>
        
        </div>
    )
}
