import React, { useState, useMemo, useEffect } from "react";

import { PageTypes } from "../../models/PageTypes.js";
import { ColorThemes } from "../../models/ColorThemes.js";
import { DisplayTypes } from "../../models/DisplayTypes.js";
import { getHomeBanners, getSlideshow } from "../../api/api.js";
import { getDisplayType } from "../../controllers/screen-controller.js";
import SlideshowComponent from "../../components/slideshow/SlideshowComponent.js";
import PageBlurb from "../../components/blurbBanners/PageBlurb.js";
import GridContent from "../../components/gridContent/GridContent.js";
import MobileGridContent from "../../components/gridContent/MobileGridContent.js";
import BoxedContent from "../../components/boxedContent/BoxedContent.js";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb.js";
import QuoteBlock from "../../components/quote/QuoteBlock.js";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner.js";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner.js";

import capabilitiesHalf from "../../assets/images/homeImages/capabilities-half.jpg";
import industriesHalf from "../../assets/images/homeImages/industries-half.jpg";
import analyticsIcon from "../../assets/images/homeImages/analytics-icon.png";
import cloudEngineeringIcon from "../../assets/images/homeImages/cloud-engineering-icon.png";
import devsecopsIcon from "../../assets/images/homeImages/devsecops-icon.png";
import gisIcon from "../../assets/images/homeImages/gis-icon.png";
import quoteHome from "../../assets/images/homeImages/quote-home.jpg";
import conclusionBackground from "../../assets/images/homeImages/conclusion-background.jpg";
import config from "../../config/config";

import './Home.css'

/**
 * 
 * @returns A page representing the entire Home page of the Niyam website
 */
export default function Home(){

    const [slideshowInfo, setSlideshowInfo] = useState([])
    const [banners, setBanners] = useState([])
    const [currentDisplay, setCurrentDisplay] = useState() 

    /**
     * Performs a GET request to retrieve all Slideshow data from Strapi
     */
    async function fetchSlideshow(){
        const slideshowJSON = await getSlideshow()

        if(slideshowJSON.data){
            setSlideshowInfo(slideshowJSON.data)
        }
    }

    /**
     * Performs a GET request to retrieve the Grid Banners from Strapi
     */
    async function fetchBanners(){
        const bannerJSON = await getHomeBanners()

        if(bannerJSON.data){
            setBanners(bannerJSON.data)
        }
    }

    useMemo(() => {
        document.title = "Home"

        setCurrentDisplay(getDisplayType())
        fetchBanners();
    }, [])

    useEffect(() => {
        fetchSlideshow();
    }, [])

    return (
        <div className="homeContainer">
            <SlideshowComponent slideshowItems={slideshowInfo}/>
            <PageBlurb
                    blurb="With our deep industry experience and relentless drive to innovate, Niyam consistently delivers breakthrough results for federal agencies and private sector clients."/>

                {/**
                 * Generates all of the Grid Banners on the Home Page using the map() function
                 */}
                {(currentDisplay === DisplayTypes.Tablet) && 
                    <div className="mobileGrids">
                        <MobileGridContent 
                            backgroundImage={capabilitiesHalf}
                            topic="TECHNICAL EXPERTISE"
                            title="Our Capabilities"
                            text="
                                Our team of analysts, engineers and developers are experts in their field and passionate about delivering innovative solutions. 
                                Whether you need Data Management or Cloud Engineering services, GIS analytics or DevSecOps solutions, Niyam has the technical skill to meet all your IT needs.
                            "
                            referenceText="View Services ›"
                            reference={`/capabilities`}/>
                        <MobileGridContent 
                            backgroundImage={industriesHalf}
                            topic="REAL-WORLD EXPERTISE"
                            title="Industries We Serve"
                            text="
                                We specialize in developing custom solutions for Emergency Preparedness & Response, Natural Resource Management, Law Enforcement & Justice, Health IT, and Global Citizen Services. 
                                Whether for public agencies or private enterprise, Niyam has the skill and experience to meet the unique technology needs of each industry.
                            "
                            referenceText="View Industries ›"
                            reference={`/industries`}/>
                    </div>
                }
                {   
                    (currentDisplay === DisplayTypes.Computer && banners) && banners.map(currentBanner => {
                        const backgroundImage = config.strapiUrl + currentBanner.attributes.BackgroundImage.data.attributes.url;
                        const textPosition = currentBanner.attributes.TextPosition
                        return (
                            <GridContent 
                                key={currentBanner.id} 
                                backgroundImage={backgroundImage}
                                textPosition={textPosition}
                                gridContent={currentBanner.attributes.BannerInfo.Content}/>
                        )
                    })
                }
                

                <div className="solutionsDiv">
                    <TopicBlurb 
                        colorTheme={ColorThemes.Light}
                        topic="Smart. Innovative. Proven." 
                        title={"For every technology need, we have a solution."}/>
                    <div className="solutionsBox">
                        <BoxedContent
                            contentImg={analyticsIcon}
                            topic="Meaningful Insights"
                            bodyText="Our data scientists are experts at extracting tangible meaning from raw information, delivering clear, actionable insights you can use."
                            reference="data-science"
                            referenceText="See Data Science, Analytics &amp; Management ›"/>
                        <BoxedContent
                            contentImg={gisIcon}
                            topic="A Global Perspective"
                            bodyText="Our GIS experts transform complex geospatial data into custom risk assessments, mission-critical apps and real-time analytics."
                            reference="gis"
                            referenceText="See GIS Analytics &amp; Applications ›"/>
                        <BoxedContent
                            contentImg={cloudEngineeringIcon}
                            topic="Secure Your Future"
                            bodyText="Let us build the flexible framework you need to grow with confidence and adapt quickly to changing technology demands."
                            reference="cloud-engineering"
                            referenceText="See Infrastructure &amp; Cloud Engineering ›"/>
                        <BoxedContent
                            contentImg={devsecopsIcon}
                            topic="Optimize Your Workflows"
                            bodyText="Our DevSecOps solutions maximize efficiency across the entire enterprise, putting you on the road to faster, more agile innovation."
                            reference="devsecops"
                            referenceText="See System Modernization - Agile, DevSecOps, Low-code/No-code & RPA ›"/>
                    </div>
                </div>

                <QuoteBlock 
                    backgroundImage={quoteHome}
                    colorTheme={ColorThemes.Dark}
                    quote="We always choose the best approach for each project to achieve the best result, working collaboratively to ensure clear outcomes at every stage."
                    author="Suman Biswas"
                    authorAffiliation="Founder, Niyam IT"/>

                <PartnersBanner pageType={PageTypes.Home}/>

                <div className="shortcutsSection">
                    <div className="shortcutsGrid">
                        <BoxedContent
                            topic="Our Story"
                            bodyText="Learn about Niyam IT's origins, our achievements and our values"
                            reference="/about-us"
                            referenceText="Read our story ›"/>
                        <BoxedContent
                            topic="Our Team"
                            bodyText="Meet the dedicated professionals behind our innovative solutions"
                            reference="/our-team"
                            referenceText="Meet the team ›"/>
                        <BoxedContent
                            topic="Our Blog"
                            bodyText="Get the latest company news and stories from around the industry"
                            reference="/blog"
                            referenceText="Catch up now ›"/>
                    </div>
                </div>

                <ConclusionBanner
                    backgroundImage={conclusionBackground}
                    title="We push the limits of technology to solve complex challenges."
                    buttonRef="/blog"
                    buttonText="Read about our outcomes"/>
        </div>
    )
}
