import React from "react";
import './CapabilitiesBlock.css'

import { ColorThemes } from "../../models/ColorThemes";

/**
 * This component is a smaller version of one of the main content blocks for all the pages under the "Capabilities" section of the website;
 * see the "Data Science" page as an example
 * 
 * @param {ColorThemes} colorTheme: Optional parameter corresponding to the current color theme of the current component (limited to: ColorThemes.Light, ColorThemes.Dark) 
 * @param {string} iconImage: Represents a link to an image source, in which the image is located inside a CapabilitiesBlock component
 * @param {string} iconFont: Represents a text counterpart to "iconImage", displaying a font-based icon rather than an image-based icon inside the current component
 * @param {string} topic: Text representing the topic of the CapabilitiesBlock component
 * @param {Array} text: Represents text content for the component
 * @param {HTMLAnchorElement} reference: Optional HTML anchor element to provide a link under the paragraph content
 * @returns Returns a React component corresponding to a miniatue version of the repeated content blocks featured in pages like the "Data Science" and "GIS" page
 */
export default function MiniCapabilitiesBlock({colorTheme = ColorThemes.Dark, iconImage, iconFont, topic, text}){

    return (
        <div className="miniCapabilitiesBlock">
            <div className="miniCapabilitiesContent" id={colorTheme}>
                {(iconImage && !iconFont) && <img className="miniBlockIcon" src={iconImage} alt="block"/>}
                {(!iconImage && iconFont) && <p className="miniBlockIconFont">{iconFont}</p>}
                <div className="miniCapabilitiesText">
                    <h4 className="miniCapabilitiesTopic">{topic}</h4>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}