import React from 'react';
import TimelineHeader from '../../components/timeline/TimelineHeader.js';
import TimelineItem from '../../components/timeline/TimelineItem.js';
import timelineBackground from '../../assets/images/timelineImages/timeline.png'
import './Timeline.css';

//TODO make this strapi
const timelineData = [
  {
    year: "2007 - 2012",
    details: [
      "Niyam IT is incorporated - `07",
      "FEMA work begins",
      { important: "First Full-time employee hired - `12" }
    ],
    position: "right"
  },
  {
    year: "2015",
    details: [
      "Staff grew to 40",
      { important: "First Prime Contract awarded" },
      "Data Science and GIS Practice established",
      "Recipient of GCN Award for IT Excellence",
      "Award from Esri for Risk Analytics work"
    ],
    position: "left"
  },
  {
    year: "2016",
    details: [
      "Certified at SBA 8(a)",
      { important: "Appraised at CMMI Level 3" },
      "Certified at ISO 9001:2015",
      "Established AWS Solutions Lab",
      "DOJ work begins",
      "GSA Schedule awarded"
    ],
    position: "right"
  },
  {
    year: "2019",
    details: [
      "Risk analytics on Anchorage earthquake",
      { important: "Featured in Alaska Governor's report to White House" },
      "FDA work begins",
      "Dept. of Agriculture work begins",
      "Certified SBA HUBZone Firm"
    ],
    position: "left"
  },
  {
    year: "2020",
    details: [
      { important: "Solution Development Center opens in Fairfax, VA" },
      "Risk analytics on Salt Lake City earthquake",
      "State of Hawaii work begins",
      "Awarded NOAA Mission Information Technology Services Contract"
    ],
    position: "right"
  },
  {
    year: "2021",
    details: [
      "Achieved ISO/IEC 20000-1:2018 and ISO/IEC 27001:2013 certifications",
      { important: "Featured in Inc. 5000" },
      "Federal Aviation Administration (FAA) eFAST awarded",
      "USSS work begins"
    ],
    position: "left"
  },
  {
    year: "2022",
    details: [
      "FEMA ICAM Contract awarded",
      { important: "Partnership with HawkEye 360" },
      "Salesforce CRM work awarded",
      "GSA STARS III awarded"
    ],
    position: "right"
  },
  {
    year: "2023",
    details: [
      { important: "Achieved 2.2x Growth in Revenue" },
      "72% Prime Work, 28% Subcontract Work",
      "SBA Disaster Analytics work awarded",
      "Supporting Saudi Government on Flood and Earthquake Disaster Risk Analytics",
      "Modernization work for FEMA Environment and Historic Preservation System",
      "Ranks #11 (out of 482 participants) for STARS III awarded Task Orders value"
    ],
    position: "left"
  },
  {
    year: "2024",
    details: [
      "FEMA Enterprise Cloud Authentication and Provisioning Services awarded",
      { important: "Top Secret Facility Clearance" },
      "Lifecycle Process Management System (LPMS) Contract awarded",
      { important: "Featured in Inc. 5000" },
      "Awarded OASIS+ Contracts",
      "NARA work begins",
      "CBP ESB BPA Awarded"
    ],
    position: "right"
  }
];


export default function Timeline() {
  return (
    <div className="timeline-container" style={{ backgroundImage: `url(${timelineBackground})` }}
    >
      <div className="timeline">
        <TimelineHeader
          title="Our Milestones"
          subtitle="A History of Achievement"
        />
        {timelineData.map((item, index) => (
          <TimelineItem
            key={index}
            year={item.year}
            details={item.details}
            position={item.position}
          />
        ))}
      </div>
    </div>
  );
}
