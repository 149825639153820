import React from "react";

import './CapabilitiesBlock.css';
import CapabilitiesOverviewBlock from "./CapabilitiesOverviewBlock";

/**
 * This component corresponds to one of the main content blocks for all the pages under the "Capabilities" section of the website;
 * see the "Data Science" page as an example
 * 
 * @param {DisplayTypes} currentDisplay: DisplayTypes value representing the current display type of the user's viewport
 * @param {string} icon: Link as a string corresponding to the icon inside a CapabilitiesBlock component
 * @param {string} topic: Text representing the topic of the CapabilitiesBlock component
 * @param {Array} textBlocks: Array of string elements representing paragraph content for the component
 * @param {TextPosition} textPosition: Represents the position of the "textBlocks" data, limited to either: TextPosition.Left or TextPosition.Right
 * @param {HTMLAnchorElement} reference: Optional HTML anchor element to provide a link under the paragraph content
 * @returns Returns a React component corresponding to the repeated content blocks featured in pages like the "Data Science" and "GIS" page
 */
export default function CapabilitiesBlock({currentDisplay, icon, topic, textBlocks, textPosition, reference}){

    return (
        <CapabilitiesOverviewBlock
            currentDisplay={currentDisplay}
            icon={icon}
            topic={topic}
            textElements={
                textBlocks && textBlocks.map((textContent, index) => {
                    return (
                        <p key={index}>{textContent}</p>
                    )
                })
            }
            textPosition={textPosition}
            reference={reference}
            />
    )
}