import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';

import Layout from './pages/layout/Layout.js';
import Home from './pages/home/Home.js';
import Capabilities from './pages/capabilities/Capabilities.js';
import DataScience from './pages/capabilities/DataScience.js';
import GIS from './pages/capabilities/GIS.js';
import CloudEngineering from './pages/capabilities/CloudEngineering.js';
import SystemModernization from './pages/capabilities/SystemModernization.js';
import Industries from './pages/industries/Industries.js';
import AboutUs from './pages/aboutUs/AboutUs.js';
import Team from './pages/team/Team.js';
import Careers from './pages/careers/CareersSimple.js';
import Resources from './pages/resources/Resources.js';
import ContactUs from './pages/contactUs/ContactUs.js';
import Blog from './pages/blog/Blog.js';
import SpecificBlogContent from './pages/blog/SpecificBlogContent.js';
import { generateCategoryMap } from './controllers/resource-controller.js';
import BlogTagContent from './pages/blog/BlogTagContent.js';

generateCategoryMap()
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        index: true,
        element: <Home/>
      },
      {
        path: "/",
        element: <Home/>
      },
      {
        path: "capabilities",
        element: <Capabilities/>
      },
      {
        path: "data-science",
        element: <DataScience/>
      },
      {
        path: "gis",
        element: <GIS/>
      },
      {
        path: "cloud-engineering",
        element: <CloudEngineering/>
      },
      {
        path: "devsecops",
        element: <SystemModernization/>
      },
      {
        path:"industries",
        element: <Industries/>
      },
      {
        path: 'about-us',
        element: <AboutUs/>
      },
      {
        path: "our-team",
        element: <Team />
      },
      {
        path: "blog",
        element: <Blog/>
      },
      {
        path: "blog/:blogID",
        element: <SpecificBlogContent/>
      },
      {
        path: 'blog/tags/:tagName',
        element: <BlogTagContent/>
      },
      {
        path: "careers",
        element: <Careers/>
      },
      {
        path: "resources",
        element: <Resources/>
      },
      {
        path: "contact-us",
        element: <ContactUs/>
      }
    ]
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router}/>
);
