import React from "react";
import './AdditionalSource.css';

/**
 * 
 * @param {string} sourceCategory: Represents category for the additional source featured in this component
 * @param {string} description: Corresponds to a description of the additional PDF source or web page
 * @param {string} buttonText: Represents the text that appears on the button, which redirects to the provided source
 * @param {string} buttonRef: Acts as the link tp some item (typically either another web page or a PDF source)
 * @returns A React component that is part of the Header component, featuring an additional source for more information on a specific category/topic
 */
export default function AdditionalSource({sourceCategory, description, buttonText, buttonRef}){
    return (
        <div className="additionalSource">
            <h5 className="sourceCategory">{sourceCategory}</h5>
            <p className="sourceDescription">{description}</p>
            <div className="sourceRef">
                <a href={buttonRef}>
                    {buttonText}
                </a>
            </div>
        </div>
    )
}