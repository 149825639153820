import React from "react";
import { useNavigate } from 'react-router-dom';
import { DisplayTypes } from "../../../models/DisplayTypes";

/**
 * 
 * @param {DisplayTypes} currentDisplay: Represents the current display type of the user's viewport 
 * @returns A React component of the "INDUSTRIES" section of the footer (different depending on the current display type)
 */
export default function IndustriesSection({currentDisplay}){
    const navigate = useNavigate()

    return (
        <>
            <button className="columnTitle" onClick={() => navigate("/industries")}>INDUSTRIES</button>

            {/**
             * If the current display is a computer, show all links that correspond to the "Industries" topic
             */}
            {(currentDisplay === DisplayTypes.Computer) && 
            <>
                <button className="columnChild" onClick={() => navigate("/industries#riskanalysis")}>
                    Emergency Preparedness & Response
                </button>
                <button className="columnChild" onClick={() => navigate("/industries#resourcemgmt")}>
                    Natural Resource Management
                </button>
                <button className="columnChild" onClick={() => navigate("/industries#justice")}>
                    Law Enforcement & Justice
                </button>
                <button className="columnChild" onClick={() => navigate("/industries#publichealth")}>
                    Health IT
                </button>
                <button className="columnChild" onClick={() => navigate("/industries#intaffairs")}>
                    Global Citizen Services
                </button>
            </>
            }
        </>
        
    )
}