import React, {useState, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import './CapabilitiesPages.css';

import TitleBanner from "../../components/titleBanner/TitleBanner";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb";
import CapabilitiesBlock from "../../components/capabilitiesBlock/CapabilitiesBlock";
import QuoteBlock from "../../components/quote/QuoteBlock";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner";
import ServicesGrid from "../../components/servicesGrid/ServicesGrid";
import MiniCapabilitiesBlock from "../../components/capabilitiesBlock/MiniCapabilitiesBlock";
import { TextPosition } from "../../models/TextPosition";
import { ColorThemes } from "../../models/ColorThemes";
import { PageTypes } from "../../models/PageTypes";
import { getDisplayType } from "../../controllers/screen-controller.js";
import { getSpecificResource } from "../../api/api.js";
import titleBackground from "../../assets/images/devsecopsImages/title-background.png"
import marketIcon from "../../assets/images/capabilitiesIcons/market-icon.png";
import devsecopsIcon from "../../assets/images/homeImages/devsecops-icon.png";
import chaosIcon from "../../assets/images/capabilitiesIcons/chaos-icon.png";
import servicesGridBackground from "../../assets/images/devsecopsImages/services-grid-background.png";
import transparencyService from "../../assets/images/devsecopsImages/transparency-service.png";
import collabService from "../../assets/images/devsecopsImages/collab-service.png";
import marketService from "../../assets/images/devsecopsImages/market-service.png";
import innovateService from "../../assets/images/devsecopsImages/innovate-service.png";
import lowriskService from "../../assets/images/devsecopsImages/lowrisk-service.png";
import experiseService from "../../assets/images/devsecopsImages/expertise-service.png";
import conclusionBackground from "../../assets/images/devsecopsImages/conclusion-background.png";

/**
 * 
 * @returns A page corresponding to the Data Science page of the Niyam website, under the path "/data-science"
 */
export default function SystemModernization(){
    const navigate = useNavigate()
    const [currentDisplay, setCurrentDisplay] = useState()
    const [agileURL, setAgileURL] = useState('')
    const [responsiveMethodURL, setResponsiveMethodURL] = useState('')

    /**
     * Performs GET request to obtain relevant PDF sources for the current page
     */
    async function getRelevantResources(){
        setAgileURL(await getSpecificResource('nit_agile.pdf'))
        setResponsiveMethodURL(await getSpecificResource('nit_responsivemethod.pdf'))
    }

    useMemo(() => {
        document.title = "System Modernization - Agile, DevSecOps, Low-code/No-code & RPA"

        setCurrentDisplay(getDisplayType())
        getRelevantResources()
    }, [])

    return (
        <div className="sysContainer">
            <TitleBanner backgroundImage={titleBackground} title="System Modernization - Agile, DevSecOps, Low-code/No-code & RPA"/>
        
            <div className="factSheet">
                <TopicBlurb 
                    colorTheme={ColorThemes.Light}
                    topic="STREAMLINE EVERYTHING" 
                    title="TRANSFORM YOUR ENTIRE OPERATION"/>
                <p className="factSheetText">
                    Niyam’s System Modernization - Agile, DevSecOps, Low-code/No-code & RPA services combine people, processes and tools to streamline workflows, facilitate collaboration and drive feedback throughout the development cycle.
                    Our Responsive Methodology™ dramatically increases transparency and speed-to-market, while minimizing risk and cost. 
                    Based on Agile and DevOps principles, RM™ is specifically designed to handle change and eliminate operational chaos; it’s the secret sauce in our IT solutions, enabling our clients to meet change with agility, flexibility and efficiency.
                </p>
                <button onClick={() => navigate(agileURL)}>
                    View Our Agile/DevSecOps Factsheet
                </button>
            </div>
            <div className="sysModernizationCapabilities">
                <CapabilitiesBlock
                    currentDisplay={currentDisplay} 
                    icon={marketIcon} 
                    topic="Boost Speed-to-Market" 
                    textBlocks={[
                        "Get your product out the door and into customers' hands faster, with fewer setbacks and lower risk.",
                        "Our solutions deliver successful outcomes through seamless execution, procedural transparency and round-the-clock attentive service during and after launch"
                    ]}
                    textPosition={TextPosition.Right}
                    />

                <CapabilitiesBlock 
                    currentDisplay={currentDisplay}
                    icon={devsecopsIcon}
                    topic="Foster Collaboration & Transparency" 
                    textBlocks={[
                        "Break down the barriers between teams and get them working together in streamlined harmony.",
                        "Our process fosters transparency between Dev & Ops, ensuring all teams are up to speed on what's working, what isn't, and where resources should be allocated"
                    ]}
                    textPosition={TextPosition.Left}
                    />

                <CapabilitiesBlock 
                    currentDisplay={currentDisplay}
                    icon={chaosIcon} 
                    topic="Eliminate Chaos" 
                    textBlocks={[
                        "Change is inevitable. Stay on your game with a plan that always expects the unexpected.",
                        "Our Responsive Methodology™ is specifically designed to handle the unexpected, weaving flexibility into your operation by proactively planning for change and designing with scalability and adaptability in mind"
                    ]}
                    textPosition={TextPosition.Right}
                    reference={
                        <a href={responsiveMethodURL}>Download the Responsive Methodology™ PDF ›</a>
                    }
                    />
            </div>

            <ServicesGrid
                backgroundImage={servicesGridBackground}
                colorTheme={ColorThemes.Dark}
                topic="AGILE / DEV SEC OPS"
                title="Features & Benefits"
                serviceComponents={[
                    <MiniCapabilitiesBlock 
                        iconImage={transparencyService}
                        topic="Transparency" 
                        text="Our real-time metrics on development processes allow program officials to track our progress"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={collabService} 
                        topic="Smart Collaboration" 
                        text="We offer practical advice, resources, tools and community support for successful deployments"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={marketService}
                        topic="Speed-to-Market" 
                        text="Our iterative, modular development approach benefits end-users with frequent usable features"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={innovateService}
                        topic="Innovate Together" 
                        text="Our teams innovate while staying committed to core values, stakeholder needs and accountability"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={lowriskService} 
                        topic="Lower Risk" 
                        text="We enable regular validation through continuous testing and short feedback loops"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={experiseService} 
                        topic="Domain Expertise" 
                        text="Using customized action plans, we establish a baseline strategy to make the process work for you"/>,
                    ]}
                />

            <QuoteBlock
                colorTheme={ColorThemes.Light}
                quote="6000+ hours of testing reduced to as little as 45 minutes! We are working our way to a clean, flexible, fast solution."
                author="Shiva Dacherla"
                authorAffiliation="System Modernization - Agile, DevSecOps, Low-code/No-code & RPA Consultant, Niyam"/>

            <ConclusionBanner
                backgroundImage={conclusionBackground}
                title="FLEXIBILITY. EFFICIENCY. SPEED."
                body="Our DevSecOps solutions deliver everything you need to succeed"
                buttonRef="/contact-us"
                buttonText="Request a consultation"/>

            <PartnersBanner pageType={PageTypes.SystemModernization} />

        </div>
    )
}
