import React from 'react';
import linkinLogo from '../../assets/images/teamImages/linkedin-logo.svg';
import './Member.css'
import { DisplayTypes } from '../../models/DisplayTypes';

/**
 * 
 * @param {DisplayTypes} currentDisplay: DisplayTypes value that corresponds to the current display type of the user (see DisplayTypes.js for all values)
 * @param {string} name: Represents the full name of the team member
 * @param {string} position: Represents the position of the team member in the company
 * @param {string} imageURL: Corresponds to the link of the team member's headshot image
 * @param {string} linkedinURL: Corresponds to the LinkedIn URL of the team member
 * @returns A React component corresponding to a single box of a team member on the Niyam website (see "Our Team" page)
 */
export default function Member({currentDisplay, name, position, imageURL, linkedinURL}) {

    const linkedinRedirect = () => {
        if(currentDisplay === DisplayTypes.Tablet){
            window.location.href = linkedinURL
        }
    }

    return (
        <div className="memberBox">
            <img className="profileImage" src={imageURL} onClick={linkedinRedirect}/>  
            <div className="memberContent">
                <div>
                    <p className="memberName">{name}</p> 
                    <p className="memberTitle">{position}</p>
                </div>

                {/**
                 * If the current user's display type is for computer, then render the LinkedIn button
                 */}
                {(currentDisplay === DisplayTypes.Computer) && 
                    <a className="linkedinLink" href={linkedinURL}>
                        <img className="linkedinLogo" width="25" height="25" src={linkinLogo}/>
                    </a>
                }
            </div>            
        </div>
    )
}
