import React from 'react';
import './ContractVehicleEntry.css'

/**
 * 
 * @param {string} imageURL: Corresponds to a link for an image source; the image serves as the logo for a specific contract vehicle
 * @param {string} title: Represents the title/name of a contract vehicle (E.x., 8(a) STAR III GWAC) 
 * @param {number} contractVehicleId: Represents the ID of the current contract vehicle entry, as assigned in Strapi
 * @param {string} contractNumber: Represents the contract number of a specific contract vehicle entry (E.x., 47QTCB22D0040 is the contract number for 8(a) STAR III GWAC)
 * @param {string} dropdownInfo: Corresponds to the markdown content of the contract vehicle entry; is displayed when the "Learn More" button is pressed.
 *                                  This markdown content represents the text description of a contract vehicle
 * @param {Function} dropdownCallback: Callback function that toggles the current contract vehicle section to display markdown content for THIS contract vehicle
 * @returns: A React component that acts as an entry for a specific contract vehicle; displays a contract vehicles:
 *              Contract Title (1), Contract Number (2), Learn More button (3)
 */
export default function ContractVehicleEntry({imageURL, title, contractVehicleId, contractNumber, dropdownInfo, dropdownCallback}) {

    return (
        <div className="contractVehicleEntry">
            <img className="contractImage" src={imageURL} alt = "Logo"/>  
                <div className="contractData">
                    <p className="contractTitle">{title}</p>
                    <p className = "contractNumber"><span className="paragraphBold">Contract Number:</span></p>
                    <p className = "contractNumber">{contractNumber}</p>
                </div>            
                
                <button type="button" className="learnMoreButton" onClick={() => {
                    dropdownCallback(dropdownInfo, contractVehicleId)
                }}>
                    <p className='buttonText'>Learn More</p>
                </button>

        </div>
    )

}
