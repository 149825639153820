import React from "react";
import { useNavigate } from 'react-router-dom';
import { DisplayTypes } from "../../../models/DisplayTypes";

/**
 * 
 * @param {DisplayTypes} currentDisplay: Represents the current display type of the user's viewport 
 * @returns A React component of the "CAPABILITIES" section of the footer (different depending on the current display type)
 */
export default function CapabilitiesSection({currentDisplay}){
    const navigate = useNavigate()

    return (
        <>
            <button className="columnTitle" onClick={() => navigate("/capabilities")}>CAPABILITIES</button>

            {/**
             * If the current display is a computer, show all links that correspond to the "Capabilities" topic
             */}
            {(currentDisplay === DisplayTypes.Computer) &&
            <>
                <button className="columnChild" onClick={() => navigate("/data-science")}>
                    Data Science - AI/ML, Analytics & Management
                </button>
                <button className="columnChild" onClick={() => navigate("/gis")}>
                    Geospatial Business Intelligence, Situational Awareness
                </button>
                <button className="columnChild" onClick={() => navigate("/cloud-engineering")}>
                    Cloud Engineered Solutions (IaC) - Zero-trust Architecture
                </button>
                <button className="columnChild" onClick={() => navigate("/devsecops")}>
                    System Modernization - Agile, DevSecOps, Low-code/No-code & RPA
                </button>
            </>
            }
        </>
    )
}