import { DisplayTypes } from "../models/DisplayTypes";
import { HeaderDisplayTypes } from "../models/HeaderDisplayTypes";

/**
 * 
 * @returns DisplayTypes value corresponding to the display type of the current user
 */
export function getDisplayType(){
    if(window.innerWidth <= 1024){
        return DisplayTypes.Tablet
    }
    else{
        return DisplayTypes.Computer
    }
}

/**
 * 
 * @returns HeaderDisplayTypes value corresponding to the display type of the current user (for the Header component ONLY)
 */
export function getHeaderDisplayType(){
    if(window.innerWidth <= 1160){
        return HeaderDisplayTypes.Hamburger
    }
    else{
        return HeaderDisplayTypes.Full
    }
}