import React, {useState, useMemo} from "react";

import TitleBanner from "../../components/titleBanner/TitleBanner";
import titleBackground from '../../assets/images/capabilitiesPageImages/title-background.png';
import analyticsIcon from '../../assets/images/homeImages/analytics-icon.png';
import gisIcon from '../../assets/images/homeImages/gis-icon.png';
import cloudEngineeringIcon from '../../assets/images/homeImages/cloud-engineering-icon.png';
import conclusionBackground from '../../assets/images/capabilitiesPageImages/conclusion-background.jpg';
import PageBlurb from "../../components/blurbBanners/PageBlurb";
import CapabilitiesOverviewBlock from "../../components/capabilitiesBlock/CapabilitiesOverviewBlock";

import { TextPosition } from "../../models/TextPosition";
import { getDisplayType } from "../../controllers/screen-controller.js";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner";

/**
 * 
 * @returns A page corresponding to the Capabilities Overview page, at the path "/capabilities"
 */
export default function Capabilities(){

    const [currentDisplay, setCurrentDisplay] = useState()

    useMemo(() => {
        document.title = "Capabilities"

        setCurrentDisplay(getDisplayType())
    }, [])

    return (
        <div className="capabilitiesOverview">
            <TitleBanner backgroundImage={titleBackground} titleBlurb="ANALYZE. OPTIMIZE. TRANSFORM." title="OUR CAPABILITIES"/>

            <PageBlurb blurb="Our precision-crafted IT solutions are specifically designed to supercharge every aspect of your operation, delivering transformational outcomes across your entire enterprise."/>

            <div className="capabilitiesOverviewBlocks">
                <CapabilitiesOverviewBlock 
                    currentDisplay={currentDisplay}
                    icon={analyticsIcon}
                    title="GAIN CLARITY AND INSIGHT"
                    topic="Data Science - AI/ML, Analytics & Management"
                    textElements={
                        [
                            <p>Extract tangible, real-world meaning from your data with our precision-crafted analytics and visualization tools. Drawing on our deep knowledge of the big data process – from planning and design to analysis and maintenance – Niyam transforms raw information into clear, relevant, actionable insights.</p>,
                            <div className="bulletPoints">
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Modern platform integrates all data sources</p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Custom-built dashboards or BYOB visualization</p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Expert analytics and full monitoring service</p>
                            </div>
                        ]  
                    }
                    textPosition={TextPosition.Left}
                    reference={
                        <a href={`/data-science`}>Explore Data Science - AI/ML, Analytics & Management ›</a>
                    }
                    />

                <CapabilitiesOverviewBlock 
                    currentDisplay={currentDisplay}
                    icon={gisIcon}
                    title="GET A GLOBAL PERSPECTIVE"
                    topic="Geospatial Business Intelligence, Situational Awareness"
                    textElements={
                        [
                            <p>Using highly-specialized tools and advanced techniques, our GIS experts collect, interpret and analyze geospatial data to reveal timely, mission-critical insights. By combining our skills in coding, visualization, queries and big data, Niyam has the ability to design GIS solutions to meet your specific needs.</p>,
                            <div className="bulletPoints">
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Get prepared with custom risk assessments </p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Deploy real-time analytics for situational awareness</p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> All-in-one toolkit delivers complete risk management</p>
                            </div>
                        ]  
                    }
                    textPosition={TextPosition.Right}
                    reference={
                        <a href={`/gis`}>Explore Geospatial Business Intelligence, Situational Awareness ›</a>
                    }
                    />
                
                <CapabilitiesOverviewBlock 
                    currentDisplay={currentDisplay}
                    icon={cloudEngineeringIcon}
                    title="BUILD A SECURE FOUNDATION"
                    topic="Cloud Engineered Solutions (IaC) - Zero-trust Architecture"
                    textElements={
                        [
                            <p>With Niyam’s Cloud Engineered Solutions (IaC) - Zero-trust Architecture services, you’ll get the flexible framework needed to grow securely and adapt more easily to future technical demands. If you're ready to embrace growth, empower innovation and future-proof your enterprise, our experts have the perfect solution.</p>,
                            <div className="bulletPoints">
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> 99.99% uptime for maximum reliability </p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Optimize resources with fit-for-purpose solutions</p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Prioritize security throughout the enterprise</p>
                            </div>
                        ]  
                    }
                    textPosition={TextPosition.Left}
                    reference={
                        <a href={`/cloud-engineering`}>Explore Cloud Engineered Solutions (IaC) - Zero-trust Architecture ›</a>
                    }
                    />
                
                <CapabilitiesOverviewBlock 
                    currentDisplay={currentDisplay}
                    icon={analyticsIcon}
                    title="ACCELERATE YOUR WORKFLOWS"
                    topic="System Modernization - Agile, DevSecOps, Low-code/No-code & RPA"
                    textElements={
                        [
                            <p>Take your enterprise out of the slow lane with our cutting-edge DevSecOps services. Our exclusive Responsive Methodology™ process dramatically improves transparency and speed-to-market while minimizing cost and risk, putting your organization securely on the road to faster, more agile innovation.</p>,
                            <div className="bulletPoints">
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Streamline processes to maximize efficiency</p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Boost productivity through collaboration</p>
                                <p className="bulletPointText"><span className="bulletPoint">Z</span> Respond to change with greater agility</p>
                            </div>
                        ]  
                    }
                    textPosition={TextPosition.Right}
                    reference={
                        <a href={`/devsecops`}>Explore System Modernization - Agile, DevSecOps, Low-code/No-code & RPA ›</a>
                    }
                    />
                
            </div>

            <ConclusionBanner
                backgroundImage={conclusionBackground}
                title="We specialize in crafting specific industry-tailored solutions"
                buttonText="Learn More"
                buttonRef="/industries"/>
        </div>
    )
}