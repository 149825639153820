import React, {useState, useMemo} from "react";
import {useParams} from 'react-router-dom';
import { getAllBlogsByTag } from "../../api/api";
import PaginationButtons from "../../components/paginationButtons/PaginationButtons";
import TitleBanner from "../../components/titleBanner/TitleBanner";
import './BlogTagContent.css';
import BlogEntry from "../../components/blogEntry/BlogEntry";
import BlogTags from "../../components/blogTags/BlogTags";
import titleBackground from '../../assets/images/blogImages/title-background.jpg';
import config from "../../config/config";

/**
 * 
 * @returns A page corresponding to blogs for a specific tag
 */
export default function BlogTagContent(){
    
    const pageSize = 4

    // Obtains the current tag through the search parameters of the current path
    const {tagName} = useParams()

    const [pageNumber, setPageNumber] = useState(1)
    const [currentPageBlogs, setCurrentPageBlogs] = useState([])

    /**
     * Performs a GET request to get all blogs under a specific tag (sorted by post date), limited in quantity by the "pageSize"
     */
    async function obtainRelevantBlogs(){
        const currentBlogsData = await getAllBlogsByTag(tagName, pageNumber, pageSize)

        if(currentBlogsData.data && currentBlogsData.data.length > 0){
            setCurrentPageBlogs(currentBlogsData.data)
        }
        else{
            setCurrentPageBlogs(["NO MORE PAGES"])
        }
    }

    useMemo(() => {
        document.title = `${tagName} - Blog`
    }, [tagName])

    useMemo(() => {
        obtainRelevantBlogs()
    }, [pageNumber])

    return (
        <div className="blogTagContent">
            <TitleBanner backgroundImage={titleBackground} title = "Our Blog"/>

            <h1 className="tagLargeTitle">{tagName}</h1>

            <div className="taggedBlogs">
                <div className="taggedBlogsList">

                    {/**
                     * Iterates through "currentPageBlogs", rendering "BlogEntry" components for each blog item under the specified tag
                     */}
                    {currentPageBlogs.map((blogData, index) => {
                        if(blogData.attributes){
                            const blogAttributes = blogData.attributes

                            return (
                                <BlogEntry
                                    key={blogAttributes.id}
                                    title={blogAttributes.Title}
                                    date={blogAttributes.PostDate}
                                    thumbnail={config.strapiUrl + blogAttributes.Thumbnail.data.attributes.url}
                                    reference={`/blog/${blogData.id}`}
                                    />
                            )
                        }
                        else{
                            return <div key={index}/>
                        }
                    })}
                </div>
            </div>

            <PaginationButtons
                showPrevButton={(pageNumber > 1)}
                showNextButton={(currentPageBlogs.length === pageSize) && (currentPageBlogs[0].attributes)}
                updatePageNumber={setPageNumber}/>

            <BlogTags/>
        </div>
    )
}