import React, {useState, useEffect} from "react";
import './PaginationButtons.css'

/**
 * 
 * @param {boolean} showPrevButton: Boolean value to determine if the previous button should be rendered;
 *                                  Clicking this button renders the next page of blogs 
 * @param {boolean} showNextButton: Boolean value to determine if the next button should be rendered;
 *                                  Clicking this button renders the next page of blogs 
 * @param {Function} updatePageNumber: Callback function, used to update the page number for another page/component
 * @returns: A React component corresponding to page buttons, appearing at the bottom of the "SpecificBlogContent", "BlogTagContent", and "Blog" pages.
 *          Clicking the next and previous buttons change the blogs displayed (for pagination)
 */
export default function PaginationButtons({showPrevButton, showNextButton, updatePageNumber}){

    const [pageNumber, setPageNumber] = useState(1)

    function decrementPageNumber(){
        setPageNumber(pageNumber-1)
    }

    function incrementPageNumber(){
        setPageNumber(pageNumber+1)
    }

    useEffect(() => {
        updatePageNumber(pageNumber)
    }, [pageNumber])

    return (
        <div className="changePageWrapper">
            <div className="changePageNumber">
                { 
                showPrevButton ? 
                    <button className="changePageBtn" onClick={decrementPageNumber}>
                        <p className="changePageText"><b>{pageNumber-1}</b></p>
                    </button>
                :
                    <button className="changePageHolder">
                        <p className="changePageTextHolder"><b>0</b></p>
                    </button>
                }

                <button className="currentPageBtn">
                    <p className="changePageText"><b>{pageNumber}</b></p>
                </button>

                {
                showNextButton ?
                    <button className="changePageBtn" onClick={incrementPageNumber}>
                        <p className="changePageText"><b>{pageNumber+1}</b></p>
                    </button>
                :
                    <button className="changePageHolder">
                        <p className="changePageTextHolder"><b>0</b></p>
                    </button>
                }
            </div>
        </div>
    )
}