import React from "react";
import { TextPosition } from "../../models/TextPosition";
import { DisplayTypes } from "../../models/DisplayTypes";

import './CapabilitiesBlock.css';

/**
 * This component corresponds to one of the main content blocks for all the pages under the "Capabilities" section of the website;
 * see the "Data Science" page as an example
 * 
 * @param {DisplayTypes} currentDisplay: DisplayTypes value representing the current display type of the user's viewport
 * @param {string} icon: Link as a string corresponding to the icon inside a CapabilitiesBlock component
 * @param {string} topic: Text representing the topic of the CapabilitiesBlock component
 * @param {Array} textBlocks: Array of string elements representing paragraph content for the component
 * @param {HTMLAnchorElement} reference: Optional HTML anchor element to provide a link under the paragraph content
 * @returns Returns a React component corresponding to the repeated content blocks featured in pages like the "Data Science" and "GIS" page
 */
export default function CapabilitiesOverviewBlock({currentDisplay, icon, title, topic, textElements, textPosition, reference}){

    return (
        <div className={`capabilitiesBlock ${textPosition === TextPosition.Left ? "textLeft" : "textRight"}`}>
            {(currentDisplay === DisplayTypes.Tablet) 
                ? <div className="capabilitiesContent">
                    <img className="blockIcon" alt={title} src={icon}/>
                    <div className="capabilitiesText">
                        <h2>{title}</h2>
                        <h3>{topic}</h3>
                        {textElements && textElements.map((textComponent, index) => {
                            return (
                                <div key={index}>
                                    {textComponent}
                                </div>
                            )
                        })}
                        <div className="capabilitiesRef">
                            {reference}
                        </div>
                    </div>
                </div>
                : <div className="capabilitiesComputerDisplay">
                    {(textPosition === TextPosition.Left) && 
                        <div className="capabilitiesContent">
                            <div className="capabilitiesText">
                                <h2>{title}</h2>
                                <h3>{topic}</h3>
                                {textElements && textElements.map((textComponent, index) => {
                                    return (
                                        <div key={index}>
                                            {textComponent}
                                        </div>
                                    )
                                })}
                                {reference}
                            </div>
                            <img className="blockIcon" alt={title} src={icon}/>
                        </div>                        
                    }
                    {(textPosition === TextPosition.Right) && 
                        <div className="capabilitiesContent">
                            <img className="blockIcon" alt={title} src={icon}/>
                            <div className="capabilitiesText">
                                <h2>{title}</h2>
                                <h3>{topic}</h3>
                                {textElements && textElements.map((textComponent, index) => {
                                    return (
                                        <div key={index}>
                                            {textComponent}
                                        </div>
                                    )
                                })}
                                {reference}
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}