import React from "react";
import './BoxedContent.css';

/**
 * 
 * @param {string} contentImg: Link to the icon featured at the top of the boxed content
 * @param {string} topic: Text representing the topic of the boxed content
 * @param {string} bodyText: Text corresponding to body text, displayed right below the content image ("contentImg")
 * @param {string} reference: Link that is referenced at the bottom of the boxed content
 * @param {string} referenceText: Placeholder text for the link from the "reference" parameter 
 * @returns: UI component corresponding to a single piece of boxed content, in which the content is arranged vertically above each other
 */
export default function BoxedContent({contentImg, topic, bodyText, reference, referenceText}){
    return (
        <div className="boxWrapper">
            <div className="boxedContent">
                {contentImg && <img className="boxedContentImg" alt="Boxed Context" src={contentImg}/>}
                <h2 className="boxedContentTopic">{topic}</h2>
                <p>{bodyText}</p>
                <a className="boxedContentRef" href={reference}>{referenceText}</a>
            </div>
        </div> 
    )
}