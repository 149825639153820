import React from "react";
import xIcon from "../../../assets/images/footerImages/x-icon.svg";
import facebookIcon from "../../../assets/images/footerImages/facebook-icon.svg";
import linkedinIcon from "../../../assets/images/footerImages/linkedin-icon.svg";

/**
 * 
 * @returns A static React component of the social media icons in the footer section
 */
export default function SocialMediaSection(){
    return (
        <div className="socialMediaIcons">
            <a href="https://twitter.com/niyamIT">
                <img width="18" height="18" src={xIcon}/>
            </a>
            <a href="https://www.facebook.com/pages/NiyamIT/336391359790138">
                <img width="18" height="18" src={facebookIcon}/>
            </a>
            <a href="https://www.linkedin.com/company/niyamit-inc-/">
                <img width="18" height="18" src={linkedinIcon}/>
            </a>
        </div>
    )
}