import React, {useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import './CapabilitiesPages.css';

import TitleBanner from "../../components/titleBanner/TitleBanner";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb";
import CapabilitiesBlock from "../../components/capabilitiesBlock/CapabilitiesBlock";
import QuoteBlock from "../../components/quote/QuoteBlock";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner";
import ServicesGrid from "../../components/servicesGrid/ServicesGrid";
import MiniCapabilitiesBlock from "../../components/capabilitiesBlock/MiniCapabilitiesBlock";
import { getDisplayType } from "../../controllers/screen-controller.js";
import { TextPosition } from "../../models/TextPosition";
import { ColorThemes } from "../../models/ColorThemes";
import { PageTypes } from "../../models/PageTypes";
import { getSpecificResource } from "../../api/api.js";
import titleBackground from "../../assets/images/cloudEngineeringImages/title-background.png";
import securityIcon from "../../assets/images/capabilitiesIcons/security-icon.png";
import reliabilityIcon from "../../assets/images/capabilitiesIcons/reliability-icon.png";
import cloudEngineeringIcon from "../../assets/images/homeImages/cloud-engineering-icon.png";
import servicesGridBackground from "../../assets/images/cloudEngineeringImages/services-grid-background.png"
import budgetService from "../../assets/images/cloudEngineeringImages/budget-service.png"
import transparencyService from "../../assets/images/cloudEngineeringImages/transparency-service.png"
import flexibilityService from "../../assets/images/cloudEngineeringImages/flexibility-service.png"
import fedrampService from "../../assets/images/cloudEngineeringImages/fedramp-service.png"
import availabilityService from "../../assets/images/cloudEngineeringImages/availability-service.png"
import securityService from "../../assets/images/cloudEngineeringImages/security-service.png"
import conclusionBackground from "../../assets/images/cloudEngineeringImages/conclusion-background.png"

/**
 * 
 * @returns A page corresponding to the Cloud Engineering page of the Niyam website, under the path "/coud-engineering"
 */
export default function CloudEngineering(){
    const navigate = useNavigate()
    const [currentDisplay, setCurrentDisplay] = useState()
    const [cloudURL, setCloudURL] = useState('')

    /**
     * Performs GET request to obtain relevant PDF sources for the current page
     */
    async function getRelevantResources(){
        setCloudURL(await getSpecificResource('nit_cloud.pdf'))
    }

    useMemo(() => {
        document.title = "Cloud Engineering"

        setCurrentDisplay(getDisplayType())
        getRelevantResources()
    }, [])

    return (
        <div className="cloudEngineeringContainer">
            <TitleBanner backgroundImage={titleBackground} title="Cloud Engineered Solutions (IaC) - Zero-trust Architecture"/>
        
            <div className="factSheet">
                <TopicBlurb 
                    colorTheme={ColorThemes.Light}
                    topic="FAST. FLEXIBLE. SECURE." 
                    title="FUTURE-PROOF YOUR ENTERPRISE"/>
                <p className="factSheetText">
                    Niyam’s Cloud Engineered Solutions (IaC) - Zero-trust Architecture services provide the solid foundation you need to expand securely and adapt to changing technical demands. 
                    Our skilled engineers help clients gain control over their toughest operational challenges, offering expert infrastructure support and fit-for-purpose cloud solutions that deliver rapid innovation capabilities. 
                    With 99.99% operational uptime and the ability to quickly troubleshoot and resolve critical issues, Niyam delivers the reliability you need to grow your business with confidence.
                </p>
                <button onClick={() => navigate(cloudURL)}>
                    View Our Cloud Engineering Factsheet
                </button>
            </div>
            <div className="cloudEngineeringCapabilities">
                <CapabilitiesBlock
                    currentDisplay={currentDisplay} 
                    icon={securityIcon}
                    topic="Top-to-Bottom Security" 
                    textBlocks={[
                        "Get the peace of mind you need to grow and expand your business confidently.",
                        "Our IT solutions are designed to prioritize security above all else: protecting your data on-site or in the cloud, keeping it moving securely across applications, and monitoring the trust of all your digital interactions"
                    ]}
                    textPosition={TextPosition.Right}
                    />

                <CapabilitiesBlock 
                    currentDisplay={currentDisplay}
                    icon={reliabilityIcon}
                    topic="Reliability, Guaranteed" 
                    textBlocks={[
                        "Our 99.99% uptime guarantee is proof of our commitment to keeping your operation running smoothly.",
                        "Our IBM Certified WebSphere consultants are experts at troubleshooting critical issues and maintaining system viability, all while deploying and maintaining applications - ensuring optimal functionality and maximum reliability"
                    ]}
                    textPosition={TextPosition.Left}
                    />

                <CapabilitiesBlock
                    currentDisplay={currentDisplay} 
                    icon={cloudEngineeringIcon} 
                    topic="Fit-For-Purpose Solutions" 
                    textBlocks={[
                        "The smart way to leverage your resources for maximum efficiency and optimal performance.",
                        "Our engineers are experts at crafting solutions to allocate the right resources to the right application workload at the right time – all tailored around specific business policies, priorities and available infrastructure"
                    ]}
                    textPosition={TextPosition.Right}
                    />
            </div>

            <ServicesGrid
                backgroundImage={servicesGridBackground}
                colorTheme={ColorThemes.Dark}
                topic="INFRASTRUCTURE / GRID"
                title="Features & Benefits"
                serviceComponents={[
                    <MiniCapabilitiesBlock 
                        iconImage={budgetService}
                        topic="Budget Conscious" 
                        text="Mange costs with a competitive analysis of cloud service providers and cost-effective strategies"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={transparencyService}
                        topic="Performance Transparency" 
                        text="IT application SLAs provide performance transparency in all key environments"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={flexibilityService} 
                        topic="Built-In Flexibility" 
                        text="Deploy fit-for-purpose cloud applications and single- or multi-cloud deployment strategies"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={fedrampService}
                        topic="FedRAMP Certified" 
                        text="Advanced skills and technical capabilities for deploying FedRAMP cloud strategies"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={availabilityService}
                        topic="Safeguard Availability" 
                        text="A wide range of business continuity options to guarantee 24/7 availability"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={securityService}
                        topic="High-Level Security" 
                        text="FISMA security plans, POAM strategies and Identity Access Management solutions"/>,
                ]}
                />

            <QuoteBlock
                colorTheme={ColorThemes.Light}
                quote="Our solutions offer drastic reductions in processing times, memory usage and bandwidth demands on system components."
                author="Joseph Satchavarodom"
                authorAffiliation="Director, Systems Engineering"/>

            <ConclusionBanner
                backgroundImage={conclusionBackground}
                title="GET THE SECURITY YOU NEED TO GROW CONFIDENTLY"
                body="Let our architects and engineers future-proof your enterprise"
                buttonRef="/contact-us"
                buttonText="Request a consultation"/>

            <PartnersBanner pageType={PageTypes.CloudEngineering} />

        </div>
    )
}
