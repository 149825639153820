import React from "react";
import './TopicBlurb.css';

/**
 * 
 * @param {ColorThemes} colorTheme: Represents the color theme of the current React component, limited to the values in ColorThemes.js (ColorThemes.Light or ColorThemes.Dark)
 * @param {string} topic: text corresponding to the topic of the block's blurb; is displayed in uppercase
 * @param {string} title: text element corresponding to the title/actual blurb of the content block
 * @returns: Component composed of only the "topic" and "title/blurb" of a content block
 */
export default function TopicBlurb({colorTheme, topic, title, topicBlurb}){
    return (
        <div className="topicWrapper">
            <div className="topicBlurb" id={colorTheme}>
                <h2 className="topicItem">{topic}</h2>
                {topicBlurb && <p>{topicBlurb}</p>}
                <h3 className="titleItem">{title}</h3>
            </div>
            <div className="centeredBar"><div className="horizontalDivider"></div></div> 
        </div>
    )
}