import React, {useState, useMemo} from "react";
import './Footer.css';

import CapabilitiesSection from "./capabilitiesSection/CapabilitiesSection";
import IndustriesSection from "./industriesSection/IndustriesSection";
import AddressComponent from "../addressComponent/AddressComponent.js";
import AboutUsSection from "./aboutUsSection/AboutUsSection";
import MiscSection from "./aboutUsSection/MiscSection";
import SocialMediaSection from "./socialMediaSection/SocialMediaSection";
import ContactInfoSection from "../contactInfoSection/ContactInfoSection";
import { getDisplayType } from "../../controllers/screen-controller.js";
import { DisplayTypes } from "../../models/DisplayTypes";
import logoFooter from '../../assets/images/logo/logo-footer.svg';

/**
 * 
 * @returns Static React component corresponding to the website's footer
 */
export default function Footer(){
    const [currentDisplay, setCurrentDisplay] = useState()

    useMemo(() => {
        // Calling "getDisplayType()" to obtain the current display type of the user's viewport
        setCurrentDisplay(getDisplayType())
    }, [])

    return (
        <div className="footerWrapper">
            {/**
             * Corresponds to Mobile display of the footer
             */}
            {(currentDisplay === DisplayTypes.Tablet) && 
                <div className="footerDiv">
                    <div className="logoDiv">
                        <a className="homeFooterRef" href={`/`}>
                            <img className="homeFooterBtn" alt="Home" src={logoFooter}/>
                        </a>
                    </div>
                    <div className="footerSections">
                        <CapabilitiesSection currentDisplay={currentDisplay}/>
                        <IndustriesSection currentDisplay={currentDisplay}/>
                        <AboutUsSection currentDisplay={currentDisplay}/>
                        <MiscSection currentDisplay={currentDisplay}/>
                    </div>
                    <div className="footerAddresses">
                        <AddressComponent title="Headquarters" address1="880 Harrison Street SE" address2="Leesburg, VA 20175"/>
                        <AddressComponent title="Solution Development Center" address1="10201 Fairfax Blvd, Suite 224" address2="Fairfax, VA 22030"/>
                    </div>
                    <ContactInfoSection/>
                    <SocialMediaSection />
                </div>
            }
            {/**
             * Corresponds to Computer display of the footer
             */}
            {(currentDisplay === DisplayTypes.Computer) && 
                <div className="footerBackground">

                    <div className="footerDiv">
                        <div className="firstColumn">
                            <a className="homeFooterRef" href={`/`}>
                                <img className="homeFooterBtn" alt="Home" src={logoFooter}/>
                            </a>
                            <AddressComponent title="Headquarters" address1="880 Harrison Street SE" address2="Leesburg, VA 20175"/>
                            <AddressComponent title="Solution Development Center" address1="10201 Fairfax Blvd, Suite 224" address2="Fairfax, VA 22030"/>
                            <ContactInfoSection/>
                        </div>
                        <div className="secondColumn">
                            <CapabilitiesSection currentDisplay={currentDisplay}/>
                        </div>
                        <div className="thirdColumn">
                            <IndustriesSection currentDisplay={currentDisplay}/>
                        </div>
                        <div className="fourthColumn">
                            <AboutUsSection currentDisplay={currentDisplay}/>
                            <MiscSection currentDisplay={currentDisplay}/>
                            <SocialMediaSection/>
                        </div>
                    </div>
                </div>
            }
            <div className="copyrightSection">
                <p>© 2024 Niyam IT. All Rights Reserved.</p>
            </div>
        </div>
        
    )
}