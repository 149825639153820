import React, { useEffect, useState } from "react";

import TitleBanner from "../../components/titleBanner/TitleBanner";
import titleBackground from '../../assets/images/aboutUsImages/title-background.png';
import './CareersSimple.css';
import CompanyValues from "./CompanyValues.js";

/**
 * 
 * @returns A page corresponding to the "Careers" page
 */
export default function Careers(){
    const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHtml = async () => {
      try {
        const response = await fetch('https://niyamit.bamboohr.com/jobs/embed2.php?version=1.0.0');
        if (response.ok) {
          const html = await response.text();
          setHtmlContent(html);
        } else {
          console.error('Failed to fetch HTML content:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };

    fetchHtml();
  }, []);
    return (
        <>
            <TitleBanner backgroundImage={titleBackground} titleBlurb="Current Openings" title="Careers at Niyam"/>
            <CompanyValues/>
            <div class="careers"
                dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
        </>
    )
}
