import React from "react";
import './HamburgerDisplay.css';
import RighthandButtons from "../righthandButtons/RighthandButtons";

/**
 * 
 * @returns A React component for the Hamburger Display of the website header (in Mobile Screens)
 */
export default function HamburgerDisplay({ closeNavigate }){

    return (
        <div className="hamburgerDisplay">
            <ul>
                <li>
                    <button onClick={() => closeNavigate("/capabilities")}>CAPABILITIES</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/data-science")}>Data Science - AI/ML, Analytics & Management</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/gis")}>Geospatial Business Intelligence, Situational Awareness</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/cloud-engineering")}>Cloud Engineered Solutions (IaC) - Zero-trust Architecture</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/devsecops")}>System Modernization - Agile, DevSecOps, Low-code/No-code & RPA</button>
                </li>
            </ul>
            <ul>
                <li>
                    <button onClick={() => closeNavigate("/industries")}>INDUSTRIES</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/industries#riskanalysis")}>Emergency Preparedness & Response</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/industries#resourcemgmt")}>Natural Resource Management</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/industries#justice")}>Law Enforcement & Justice</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/industries#publichealth")}>Health IT</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/industries#intaffairs")}>Citizen Services & International Affairs</button>
                </li>
            </ul>
            <ul>
                <li>
                    <button onClick={() => closeNavigate("/about-us")}>ABOUT US</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/about-us")}>Our Story</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/about-us#socialresp")}>Social Responsibilities</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/about-us#certifications")}>Certifications</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/about-us#contracts")}>Contract Vehicles</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/our-team")}>Meet the Team</button>
                </li>
            </ul>
            <ul>
                <li>
                    <button onClick={() => closeNavigate("/blog")}>BLOG</button>
                </li>
            </ul>
            <ul>
                <li>
                    <button onClick={() =>  closeNavigate("/careers")}>CAREERS</button>
                </li>
                <li>
                    <button onClick={() => closeNavigate("/about-us#careers")}>Employee Benefits</button>
                </li>
            </ul>
            <div className="hamburgerRefs">
                <RighthandButtons/>
            </div>
        </div>
    )
}