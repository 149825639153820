import React from "react";
import Markdown from "react-markdown";
import { TextPosition } from "../../models/TextPosition";

import './GridContent.css'

/**
 * @param {string} backgroundImage: Corresponds to the link of an image source, which serves as the background image for the current component
 * @param {TextPosition} textPosition: Represents the position of the text in the current component (limited to values in TextPosition.js - TextPosition.Left and TextPosition.Right)
 * @param {string} gridContent: Corresponds to markdown content of text that is displayed on either the left or right side of the current component 
 * @returns React component in the shape of a grid; one half is an image while the other half is text (see the "Home" page)
 */
export default function GridContent({backgroundImage, textPosition, gridContent}){

    return (
        <div className="gridBlock">
            {/**
             * Render the text on the left of the image
             */}
            {(textPosition == TextPosition.Left) && 
                <div className="gridArrangement">
                    <div className="gridContent" id={textPosition}>
                        <Markdown 
                            children={gridContent} />
                    </div>
                    
                    <div className="gridBackground" style={{
                        backgroundImage: `url(${backgroundImage})`
                    }}></div>
                    
                </div>
            }
            {/**
             * Render the text on the right of the image
             */}
            {(textPosition == TextPosition.Right) &&
                <div className="gridArrangement">
                    <div className="gridBackground" style={{
                        backgroundImage: `url(${backgroundImage})`
                    }}></div>

                    <div className="gridContent" id={textPosition}>
                        <Markdown 
                            children={gridContent} />
                    </div>
                </div>
            }
        </div>  
    )
}