import React from "react";
import './ContactInfoSection.css';
import phoneIcon from "../../assets/images/footerImages/phone-icon.png";
import mailIcon from "../../assets/images/footerImages/mail-icon.png";

/**
 * 
 * @returns A static React component corresponding to the contact info (phone number and email), which appears in the "Footer" section and the "Contact Us" page
 */
export default function ContactInfoSection(){
    return (
        <div className="contactInfoSection">
            <div className="contactInfo">
                <img alt="phone" src={phoneIcon}/>
                <a href="tel:7034292450">+1 703.429.2450</a>
            </div>
            <div className="contactInfo">
                <img alt="email" src={mailIcon}/>
                <a href="mailto:info@niyamit.com" target="_top">info@niyamit.com</a>
            </div>
        </div>
    )
}