import React from "react";
import TopicBlurb from "../blurbBanners/TopicBlurb";

import './ServicesGrid.css';

/**
 * 
 * @param {string} backgroundImage: Optional string that represents the image source for the background image of the current component
 * @param {ColorThemes} colorTheme: Represents the color theme for the current component (either ColorThemes.Light or ColorThemes.Dark)
 * @param {string} topic: Corresponds to the topic for the 'TopicBlurb' component
 * @param {string} title: Corresponds to the title for the 'TopicBlurb' component
 * @param {Array[JSX.Element]} serviceComponents: Serves as an array of JSX elements that are displayed in a grid format, representing the core content of the component
 * @param {HTMLAnchorElement} reference: Optional HTMLAnchorElement corresponding to a link to a resource
 * @returns: A React component that displays content in a grid-like arrangment (2 columns)
 */
export default function ServicesGrid({urlFragment, backgroundImage, colorTheme, topic, title, serviceComponents, reference}){
    return (
        <div id={urlFragment} className="servicesGrid" style={{
            backgroundImage: `url('${backgroundImage}')`,
            backgroundSize: 'cover'
        }}>
            {(topic || title) && 
                <TopicBlurb colorTheme={colorTheme} topic={topic} title={title} />
            }

            <div className="servicesGridContent">
                {serviceComponents && serviceComponents.map((component, index) => {
                        return (
                            <p key={index} className="serviceComponent">{component}</p>
                        ) 
                    })
                }
                <div className="servicesReference">
                    {reference}
                </div>
            </div>
           
        </div>
    )
}