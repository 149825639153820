import React from "react";
import './QuoteBlock.css';

/**
 * 
 * @param {string} backgroundImage: Represents the link to the background image of the current quote block
 * @param {string} colorTheme: String-representation of the color theme of the quote bllock (limited to values in ColorTheme.js)
 * @param {string} quote: Represents the actual quote in the quote block
 * @param {string} quoteAuthor: Displays the author of the inputted quote
 * @param {string} authorAffiliation: Represents the affiliation of the quote's author (what company does the quoter work at?)
 * @returns A React component that displays a quote (see bottom of "Home Page")
 */
export default function QuoteBlock({backgroundImage, colorTheme, quote, author, authorAffiliation}){

    return (
        <div className="quoteWrapper" id={colorTheme} style={{
            backgroundImage: `url('${backgroundImage}')`,
        }}>
            <div className="quoteContent">
                <h2 className="quoteText">"{quote}"</h2>
                <h6 className="author">{author}</h6>
                <h6>{authorAffiliation}</h6>
            </div>
        </div>
    )
}