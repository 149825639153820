import React , {useState, useEffect, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import TitleBanner from "../../components/titleBanner/TitleBanner";
import ServicesGrid from "../../components/servicesGrid/ServicesGrid";
import FeaturedClient from "../../components/featuredClient/FeaturedClient";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner";
import { ColorThemes } from "../../models/ColorThemes";
import { PageTypes } from "../../models/PageTypes";
import { getDisplayType } from "../../controllers/screen-controller.js";
import { redirectToFragment } from "../../controllers/url-fragment-controller.js"; 
import titleBackground from "../../assets/images/industriesImages/title-background.png";
import femaBackground from "../../assets/images/industriesImages/fema-background.jpg";
import dotBackground from "../../assets/images/industriesImages/dot-background.jpg";
import dojBackground from "../../assets/images/industriesImages/doj-background.jpg";
import fdaBackground from "../../assets/images/industriesImages/fda-background.jpg";
import usaidBackground from "../../assets/images/industriesImages/usaid-background.jpg";
import { getSpecificResource } from "../../api/api.js";

import './Industries.css';

/**
 * 
 * @returns A page corresponding to the Industries page of the Niyam website
 */
export default function Industries(){
    const navigate = useNavigate()
    const [currentDisplay, setCurrentDisplay] = useState()
    const [xformHITURL, setXformHITURL] = useState('')

    /**
     * Performs GET request to get the xform HealthIT source
     */
    async function getRelevantResources(){
        setXformHITURL(await getSpecificResource('nit_xform_Health_IT_2021.pdf'))
    }

    useMemo(() => {
        document.title = "Industries"

        getRelevantResources()
        setCurrentDisplay(getDisplayType())
    }, [])

    useEffect(() => {
        redirectToFragment()
    }, [])

    return (
        <div className="industriesWrapper">
            <TitleBanner
                backgroundImage={titleBackground}
                titleBlurb="WHERE WE SHINE"
                title="OUR INDUSTRIES"/>
            
            <div className="factSheetWrapper">
                <div className="factSheet">
                    <p className="factSheetText">
                        Since our founding in 2007, Niyam has been consistently focused on crafting innovative solutions to complex technical challenges. 
                        With a growing list of clients across 5 domains — 
                        <span className="paragraphBold"> Emergency Preparedness & Response, Natural Resource Management, Law Enforcement & Justice, Health IT, and Global Citizen Services </span>
                        — we are quickly becoming the leading provider of critical, industry-tailored technologies. 
                        Whether deployed across public agencies or implemented by private enterprise, our innovative technology solutions consistently deliver measurable results for every stakeholder.
                    </p>
                    <button onClick={() => navigate(`/contact-us`)}>
                        Request a consultation
                    </button>
                </div>
            </div>

            <ServicesGrid
                urlFragment="riskanalysis"
                currentDisplay={currentDisplay}
                colorTheme={ColorThemes.Light}
                topic="PREPARE. PLAN. MITIGATE."
                title="Emergency Preparedness & Response IT"
                serviceComponents={[
                    <p>
                        <span className="paragraphBold">We Are Risk Assessment Experts. </span>
                        Our team was called upon to help design FEMA's Hazards U.S.-Multiple Hazards (Hazus-MH) software, which has been implemented by emergency management organizations around the world.
                    </p>,
                    <p>
                        <span className="paragraphBold">Situational Awareness. </span>
                        Our spatial modeling software delivers top-down aggregated representations of the relationship between a hazard and its geography, providing a complete data picture in an easy-to-use interface.
                    </p>,
                    <p>
                        <span className="paragraphBold">Every Angle, Every Possibility. </span>
                        We offer custom solutions for both man-made and natural hazards, covering all realms including mitigation planning, emergency planning, first response, regulatory policies and catastrophe modeling.
                    </p>,
                    <p>
                        <span className="paragraphBold">Risk Management Toolkit. </span>
                        Our Risk Management Toolkit combines GIS, big data and custom software to provide a complete "pre-covery" solution for accidents and disasters.
                    </p>,
                    <p>
                        <span className="paragraphBold">Minute-by-Minute Planning. </span>
                        Incredibly accurate, time-scaled solutions are our specialty. We utilize our talents to reduce response time and mitigate damage from disasters.
                    </p>
                ]}/>

            <FeaturedClient
                backgroundImage={femaBackground}
                client="FEMA"
                department="U.S. Department of Homeland Security"
                text="
                    We work closely with FEMA in its efforts in disaster relief and emergency response protocols that affect our nation. We modernize and maintain Hazus as part of the Risk MAP Program using GIS-based spatial and analytics methodologies to conduct algorithmic predictive analytics to forecast hazard risks. 
                    Our accurate risk analysis was used by Alaska Governor Dunleavy in his Official Request to the White House following the 2018 7.1-magnitude Alaskan Earthquake. Additionally, Niyam re-architected the Hazus software framework for flood, hurricane, and earthquake to improve runtime performance by over 500% and reduced O&M costs by 44%.
                "
                blogLink="blog"/>

            <ServicesGrid
                urlFragment="resourcemgmt"
                currentDisplay={currentDisplay}
                colorTheme={ColorThemes.Light}
                topic="PROTECT CRITICAL RESOURCES"
                title="Natural Resource Management IT"
                serviceComponents={[
                    <p>
                        <span className="paragraphBold">A Trusted Industry Partner. </span>
                        Our work with the DOT and the National Pipeline Mapping Service underscores our status as a trusted industry partner. Our dedicated team of floodplain experts excel in handling the management and transmission of the Earth’s natural resources.
                    </p>,
                    <p>
                        <span className="paragraphBold">Efficient Delivery. </span>
                        We use accelerated DevSecOps solutions to implement innovative Continuous Integration/Continuous Delivery (CI/CD) techniques, for maximum efficiency in delivering the most effective, secure results.
                    </p>,
                    <p>
                        <span className="paragraphBold">Data for Your Needs. </span>
                        Our expert floodplain capabilities are flexible enough to handle any job. We understand the need for geospatial data analytics to approach any challenge with a plan and a solution that yields measurable outcomes.
                    </p>,
                    <p>
                        <span className="paragraphBold">Reach Your Goals. </span>
                        Our custom content management solutions connect data and imaging-capture capabilities, offering clear visualization of pathways and pipelines for real and potential events in natural resource transmission.
                    </p>,
                    <p>
                        <span className="paragraphBold">Maintained and Secured. </span>
                        Our experienced team excels in maintaining architecture to the highest quality while consistently securing data against any potential threats.
                    </p>
                ]}/>

            <FeaturedClient
                backgroundImage={dotBackground}
                client="PHMSA"
                department="U.S. Department of Transportation"
                text="
                    For USDOT PHMSA, Niyam leads the development and maintenance of the National Pipeline Mapping System (NPMS), which provides interactive maps showing the locations of hazardous liquid pipelines, gas transmission pipelines and liquefied natural gas plants. 
                    Our work aims to assist general public query information related to these pipelines and plants through a GIS-based Map Viewer. To enhance human interaction, a program view dashboard was developed to provide information on each county and community status on the NPMS. 
                    We work closely with stakeholders to ensure access to the most current information for the public.
                "
                blogLink="blog"/>
            
            <ServicesGrid
                urlFragment="justice"
                currentDisplay={currentDisplay}
                colorTheme={ColorThemes.Light}
                topic="MAXIMIZE DATA SECURITY"
                title="Law Enforcement & Justice IT"
                serviceComponents={[
                    <p>
                        <span className="paragraphBold">A Trusted Industry Partner. </span>
                        Our work in law enforcement and justice IT has earned us the trust of stakeholders across the sector. We provide server management for the DOJ on many fronts, including handling data and memory across hundreds of servers for dedicated and virtual hosts.
                    </p>,
                    <p>
                        <span className="paragraphBold">Database Security. </span>
                        We architect and configure judicial databases to safely transfer and manage large volumes of data. We provide comprehensive Root Analysis for system failures, allowing vulnerabilities to be mitigated quickly.
                    </p>,
                    <p>
                        <span className="paragraphBold">Top-to-Bottom Customization. </span>
                        Our team modernizes IT platforms for judicial clients from the ground up, taking into consideration existing pain points and best practices to reduce complexity and increase efficiency and accessibility.
                    </p>,
                    <p>
                        <span className="paragraphBold">Identity Management. </span>
                        Using our own cloud-based applications, we architected and deployed Identity Credential Access Management solutions with Personal Identity Verification (PIV) card authentication for the DOJ.
                    </p>,
                    <p>
                        <span className="paragraphBold">More Efficient Memory. </span>
                        We improved data handling and memory size at the expense of peak floating point operations per second (FLOPS), and crafted an efficient storage solution for the DOJ's September 11th Victim Compensation Fund.
                    </p>
                ]}/>

            <FeaturedClient
                backgroundImage={dojBackground}
                client="SEPTEMBER 11th VICTIM COMPENSATION FUND"
                department="U.S. Department of Justice"
                text="
                    Niyam directly supports the DOJ's September 11th Victim Compensation Fund (VCF), which leads compensation efforts for those who suffer from the effects of the terrorist-related crashes on September 11, 2001 and the subsequent debris-removal efforts. 
                    We are responsible for the design and maintenance of the www.vcf.gov portal, which allows for all parties involved to access information, upload documents, start and a claim and monitor the process. 
                    Our efforts to improve the quality of claim submissions has streamlined the review process and improved transparency by providing public reports on a weekly, quarterly and annual basis.
                "
                blogLink="blog"/>

            <ServicesGrid
                urlFragment="publichealth"
                currentDisplay={currentDisplay}
                colorTheme={ColorThemes.Light}
                topic="FLEXIBLE, MODERN SOLUTIONS"
                title="Health IT"
                serviceComponents={[
                    <p>
                        <span className="paragraphBold">Mobile Enabled Healthcare. </span>
                        Our architects designed clinical workflows that run on iOS and perform semantic searches on healthcare data residing in HBase on Hadoop clusters. These mobile enabled solutions enable remote patient monitoring and better access to quality of health care.
                    </p>,
                    <p>
                        <span className="paragraphBold">Compliance and Privacy. </span>
                        Our Master Data Management (MDM) algorithms simplify insurance data management for healthcare providers. They consolidate member data acquired from multiple systems on a daily basis and ensure uniformity, accuracy, stewardship, semantic consistency and accountablity. We have set privacy and compliance standards, guidelines and procedures for ETL specific to patient data.
                    </p>,
                    <p>
                        <span className="paragraphBold">Cloud Accessibility. </span>
                        Our cloud-based processes enable the pharmaceutical industry users to manage and complete tasks required for the drug review process by the FDA. The centralized work management hub improves user experience and makes the review and approval process efficient.
                    </p>,
                    <p>
                        <span className="paragraphBold">Scalable Security. </span>
                        With the extreme scalability of our ICAM tools deployed for FEIMS/FAMS, FEMA was able to authorize and engage around 9000 users belonging to Surge Capacity Force, IRS, and technology vendors, over the course of 4 months to process and verify funds for COVID-19 funeral assistance program.
                    </p>,
                    <p>
                        <span className="paragraphBold">GIS Enabled Healthcare. </span>
                        We extended the capabilities of Hazus, a globally applicable standardized tool to model risks and losses during natural disasters. The extended analyses helped generate distribution plans and supply chain scenarios for COVID-19 vaccines during natural disasters. Our accurate geo-location data and analyses bolstered FEMA’s virtual operations by providing a safe and accurate alternative to onsite assessments.
                    </p>,
                    <p>
                        <span className="paragraphBold">Natural Language Processing for Clinical Trials. </span>
                        We developed our NLP engine prototype as an extension of Biomedical Translational Research Information System (BTRIS). It ingests proprietary corpus components, understands the format of both, the study protocol and patient record, and runs “match” processes for clinical trials. It reduces time and manual efforts required in processing large amounts of unstructured data and speeds up clinical trials.
                    </p>
                ]}
                reference={
                    <a href={xformHITURL}>
                        Download the xformHIT™ Methodology PDF ›
                    </a>
                }
                />             
            
            <FeaturedClient
                backgroundImage={fdaBackground}
                client="FDA"
                department="U.S. Department of Health & Human Services"
                text="
                    Niyam led an agile team to design and implement a modern, responsive microservice-based UI prototype for a paperless FDA Generic Drug Review process. 
                    The working prototype showcases FDA’s vision for modernizing complex drug review processes and data exchanges between drug manufacturer, industries, and FDA reviewers and officials. 
                    We also led a team to design the UI for the digitization of the formal review process for the expiration of various strategic drug stockpiles like Ciprofloxacin (prevention of Anthrax attacks) and Potassium Iodide (treatment of wide-scale radioactive exposure).
                "
                blogLink="blog"/>

            <ServicesGrid
                urlFragment="intaffairs"
                currentDisplay={currentDisplay}
                colorTheme={ColorThemes.Light}
                topic="OPTIMIZE GLOBAL LOGISTICS"
                title="Global Citizen Services IT"
                serviceComponents={[
                    <p>
                        <span className="paragraphBold">Domestic & International IT Experts. </span>
                        We work closely with the United States Agency for International Development (USAID) to support relief efforts to those across our nation’s borders, and apply our technical and logistical know-how to improve foreign aid efforts on several fronts.
                    </p>,
                    <p>
                        <span className="paragraphBold">International Government. </span>
                        We are experienced in working with governments outside the U.S., assisting with the procurement of necessary supplies and streamlining the supply chain for citizens around the globe.
                    </p>,
                    <p>
                        <span className="paragraphBold">Supply Chain Management. </span>
                        Our team architected and developed a ‘Reports-on-Demand’ system for USAID to assist with the management of thousands of data points aimed towards the supply of essential medicines and other products to people impacted by HIV/AIDS.
                    </p>,
                    <p>
                        <span className="paragraphBold">Business Intelligence. </span>
                        We tackle the most complex of challenges to increase business intelligence for federal agencies that deal with international affairs, thus giving greater insights on how to approach complex challenges with a greater positive impact on stakeholders.
                    </p>,
                    <p>
                        <span className="paragraphBold">Worldwide Affairs. </span>
                        Our mission-based services and applications stretch beyond our own borders. We believe in worldwide well-being and sharing our talents to global citizens to improve the livelihood of all people.
                    </p>
                ]}/>

            <FeaturedClient
                backgroundImage={usaidBackground}
                client="GLOBAL HEALTH SUPPLY CHAIN"
                department="U.S. Agency for International Development"
                text="
                    Niyam directly supports USAID’s mission to ensure the supply of quality essential medicines and other products to those impacted by HIV/AIDS. 
                    Our work has helped provide over $2.5 billion in life-saving commodities with more than 25,000 shipments to 62 countries. 
                    This includes 240 million HIV/AIDS rapid test kits, 4 million VMMC kits, and over 70% of antiretrovirals funded through the President’s Emergency Plan for AIDS Relief (PEPFAR). 
                    We are also instrumental in training 20,000 Health IT professionals in supply chain management, developing the ForLab quantification tool with the Clinton Health Access Initiative to forecast laboratory commodity needs, saving over $176 million in freight costs through forecasting, supply planning, and logistical adjustments.
                "
                blogLink="blog"/>

            <PartnersBanner pageType={PageTypes.Industries} />
        </div>
    )
}