import React, { useState, useMemo } from 'react';
import { getTeamMembers } from '../../api/api.js';
import { getDisplayType } from "../../controllers/screen-controller.js";
import TitleBanner from '../../components/titleBanner/TitleBanner.js';
import PageBlurb from '../../components/blurbBanners/PageBlurb.js';
import Member from '../../components/member/Member.js';
import ConclusionBanner from '../../components/conclusionBanner/ConclusionBanner.js';
import titleBackground from "../../assets/images/teamImages/title-background.png";
import conclusionBackground from "../../assets/images/teamImages/conclusion-background.jpg";
import config from "../../config/config";

import './Team.css';

/**
 * 
 * @returns A page representing the "Our Team" page on Strapi
 */
export default function Team(){
    const [teamMembers, setTeamMembers] = useState([])
    const [currentDisplay, setCurrentDisplay] = useState()

    /**
     * Performs GET request to obtain all team members from Strapi
     */
    async function fetchTeam(){
        const teamJSON = await getTeamMembers()

        if(teamJSON.data){
            setTeamMembers(teamJSON.data)
        }
    }

    useMemo(() => {
        document.title = "Our Team"
        fetchTeam();
        setCurrentDisplay(getDisplayType())
    }, [])

    return (
        <div className="memberSection">
            <TitleBanner backgroundImage={titleBackground} title="OUR TEAM"/>
            <PageBlurb blurb="Meet the dedicated professionals behind our success"/>
                <div className="centeredMembers">
                    <div className="teamMembers">
                        
                        {/**
                         * Iterates through the list of Team Members from Strapi
                         * Data of each Team Member (name, position, image, linkedIn) is represented in JSON format
                         */}
                        {
                            teamMembers && teamMembers.map((member) => {
                                const imageURL = config.strapiUrl+member.attributes.ProfileImage.data.attributes.url;

                                return (
                                    <Member
                                        currentDisplay={currentDisplay}
                                        key={member.id}
                                        name={member.attributes.Name}
                                        position={member.attributes.Position}
                                        imageURL={imageURL}
                                        linkedinURL={member.attributes.LinkedInURL}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <ConclusionBanner
                    backgroundImage={conclusionBackground}
                    title="ELEVATE YOUR CAREER"
                    body="Join our winning team and make your mark on the industry"
                    buttonRef="/careers"
                    buttonText="See open positions"
                    />    
        </div>
    )
    
}
