import React, {useState, useMemo} from "react";
import SmallBlogEntry from "../blogEntry/SmallBlogEntry";
import './FeaturedBlogSection.css';
import { getAllBlogsByTag } from "../../api/api";

/**
 * 
 * @param {string} sectionTopic: Represents an existing blog tag name (E.x., Health, Justice, Data, Hazus, etc.)
 * @param {number} pageSize: Corresponds to the number of blogs that will show up in the Featured Blog section (allows for pagination)
 * @returns: A React component corresponding to recent blog entries (SmallBlogEntry component) for a featured blog tag (E.x., recent blogs under "Health" tag)
 */
export default function FeaturedBlogSection({sectionTopic, pageSize}){

    const [topicBlogs, setTopicBlogs] = useState([])

    /**
     * Performs GET request to get all blogs under the specified tag (sectionTopic), limited in quantity by "pageSize"
     */
    async function pullTopicBlogs(){
        const pulledTopicBlogs = await getAllBlogsByTag(sectionTopic, 1, pageSize)
        if(pulledTopicBlogs.data){
            setTopicBlogs(pulledTopicBlogs.data)
        }
    }

    useMemo(() => {
        pullTopicBlogs()
    }, [])

    return (
        <div className="sectionBlogs">
            <h2 className="sectionLabel">{sectionTopic}</h2>
            <div className="featuredSectionBlogs">

                {/**
                 * Iterates through all items in "topicBlogs", rendering a SmallBlogEntry component for each element (displays a blog's title and post date ONLY)
                 */}
                {topicBlogs && topicBlogs.map(blogData => {
                    const blogAttributes = blogData.attributes;

                    return (
                        <SmallBlogEntry
                            key={blogData.id}
                            title={blogAttributes.Title}
                            date={blogAttributes.PostDate}
                            reference={`/blog/${blogData.id}`}
                            />
                    )
                    
                })}
                
            </div>
        </div>
    )
}