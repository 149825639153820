import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm"; 

import './SlideshowItem.css';

/**
 * 
 * @param {string} backgroundImage: Corresponds to a link to an image source, serving as the background image for the current slideshow
 * @param {string} bannerContent: Represents the markdown content for a specific slideshow item 
 * @returns A React component representing a slideshow content block for the slideshow as a whole (see "Home Page")
 */
export default function SlideshowItem({backgroundImage, bannerContent}){

    return (
        <div className="slideshowBackground" style={{
            backgroundImage: `url('${backgroundImage}')`
        }}>
            <div className="slideshowComponent">
                <Markdown 
                    children={bannerContent} 
                    remarkPlugins={[remarkGfm]} />
            </div>
        </div>
    )
}
