import React from "react";
import { useNavigate } from 'react-router-dom';

/**
 * 
 * @returns A React component of the "BLOG", "RESOURCES", and "CONTACT US" section of the footer (different depending on the current display type)
 */
export default function MiscSection(){
    const navigate = useNavigate()

    return (
        <div className="columnChildren">
            <button className="columnChild" onClick={() => navigate("/blog")}>
                BLOG
            </button>
            <button className="columnChild" onClick={() => navigate("/careers")}>
                CAREEERS
            </button>
            <button className="columnChild" onClick={() => navigate("/resources")}>
                RESOURCES
            </button>
            <button className="columnChild" onClick={() => navigate("/contact-us")}>
                CONTACT
            </button>
        </div>
    )
}