import React, {useMemo} from "react";

import './Resources.css';
import TitleBanner from "../../components/titleBanner/TitleBanner";
import ResourceSection from "../../components/resourceSection/ResourceSection";
import { ResourceCategory } from "../../models/ResourceCategory";

import titleBackground from '../../assets/images/resourcesImages/title-background.png';
import conclusionBackground from '../../assets/images/resourcesImages/conclusion-background.jpg';
import twitterIcon from "../../assets/images/footerImages/twitter-icon.svg";
import facebookIcon from "../../assets/images/footerImages/facebook-icon.svg";
import linkedinIcon from "../../assets/images/footerImages/linkedin-icon.svg";
import PageBlurb from "../../components/blurbBanners/PageBlurb";

/**
 * 
 * @returns A page corresponding to the Resources page of the Niyam website, under the path "/resources"
 */
export default function Resources(){

    useMemo(() => {
        document.title = "Resources"
    }, [])

    return (
        <div className="resourceLibrary">
            <TitleBanner
                backgroundImage={titleBackground}
                titleBlurb="LEARN MORE"
                title="RESOURCE LIBRARY"
                />

            <PageBlurb
                blurb="Want to dig a little deeper into what we do? Browse the resources below to learn more about our skills and how we put them to use for our clients and our communities."/>

            <div className="resourceSections">
                <ResourceSection 
                    sectionTitle="– Fact Sheets & White Papers –" 
                    resourceCategories={
                        [ResourceCategory.FactSheet, ResourceCategory.WhitePaper]
                    }
                    />
                <ResourceSection 
                    sectionTitle="– Brochures & Presentations –" 
                    resourceCategories={
                        [ResourceCategory.Brochure, ResourceCategory.Presentation]
                    }
                    />
                <ResourceSection 
                    sectionTitle="– Infographics –" 
                    resourceCategories={
                        [ResourceCategory.Infographic]
                    }
                    />
            </div>

            <div className="conclusionBanner" style={{
                backgroundImage: `url('${conclusionBackground}')`
            }}>
                <h1 className="conclusionTitle">CONNECT. FOLLOW. SHARE.</h1>
                <p className="conclusionBody">Engage with us and stay in-the-know about all things Niyam.</p>
                <div className="socialMediaLinks">
                    <a href="https://twitter.com/niyamIT">
                        <img alt="Twitter" src={twitterIcon} className="conclusionIcons"/>
                    </a>
                    <a href="https://www.facebook.com/pages/NiyamIT/336391359790138">
                        <img alt="Facebook" src={facebookIcon} className="conclusionIcons"/>
                    </a>
                    <a href="https://www.linkedin.com/company/niyamit-inc-/">
                        <img alt="LinkedIn" src={linkedinIcon} className="conclusionIcons"/>
                    </a>
                </div>
            </div>

        </div>
    )
}