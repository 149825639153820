import React, {useState, useMemo} from "react";
import { useNavigate } from 'react-router-dom';

import { getHeaderDisplayType } from "../../controllers/screen-controller.js";
import { HeaderDisplayTypes } from "../../models/HeaderDisplayTypes";

import hamburgerIcon from '../../assets/images/headerImages/hamburger-icon.png';
import exitIcon from '../../assets/images/headerImages/exit-icon.svg';
import companyLogo from "../../assets/images/logo/logo-header-dark.svg";
import PopupLinks from "./popupLinks/PopupLinks";
import RighthandButtons from "./righthandButtons/RighthandButtons";

import "./Header.css";
import HamburgerDisplay from "./hamburgerDisplay/HamburgerDisplay";
import PopupDisplay from "./popupDisplay/PopupDisplay";
import { HeaderCategory } from "../../models/HeaderCategory";

/**
 * 
 * @returns Static React component corresponding to the Header of the website
 */
export default function Header(){

    const [headerDisplay, setHeaderDisplay] = useState()
    const [hamburgerOpen, setHamburgerOpen] = useState(false)
    const [popupOpen, setPopupOpen] = useState(false)
    const [headerCategory, setHeaderCategory] = useState()
    const navigate = useNavigate()


    const closeNavigate = (link) => {
        if(popupOpen){
            setPopupOpen(false)
            setHeaderCategory(HeaderCategory.Unopened)
        } else if(hamburgerOpen) {
            setHamburgerOpen(false)
        }
        navigate(link)
    }
    
    const togglePopup = (newCategory) => {
        if(headerCategory === newCategory && popupOpen){
            setPopupOpen(false)
            setHeaderCategory(HeaderCategory.Unopened)
        } else {
            setPopupOpen(true)
            setHeaderCategory(newCategory)
        }
    }

    useMemo(() => {
        setHeaderDisplay(getHeaderDisplayType())
    }, [])

    return (
        <div className="headerContent">

            {/**
             * Check Header display type
             */}
            {(headerDisplay === HeaderDisplayTypes.Full) 
                ? <div className="browserHeader">
                    <div className="headerLinks">
                        <a className="logoLink" href={`/`}>
                            <img className="logo" alt="Niyam IT" src={companyLogo}/>                
                        </a>
                        <PopupLinks togglePopup={togglePopup} closeNavigate={closeNavigate}/>
                        <div className="righthandButtonsDisplay">
                            <RighthandButtons/>
                        </div>
                    </div>
                </div>
                : <div className="mobileHeader">
                    <div className="hamburgerHeader">
                        <button onClick={() => setHamburgerOpen(!hamburgerOpen)}>
                            {hamburgerOpen 
                                ? <img className="exitIcon" src={exitIcon} alt="exitIcon"></img>
                                : <img className="openIcon" src={hamburgerIcon} alt="hamburgerIcon"></img>
                            }
                        </button>
                    </div>
                    <div className="logoHeader">
                        <a className="logoLink" href={`/`}>
                            <img className="logo" alt="Niyam IT" src={companyLogo}/>                
                        </a>
                    </div>
                </div>
            }

            {hamburgerOpen && <HamburgerDisplay closeNavigate={closeNavigate} />}

            {popupOpen && <PopupDisplay headerCategory={headerCategory} closeNavigate={closeNavigate}/>}
            
        </div>
    )
}
