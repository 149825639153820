import React from "react";
import './AddressComponent.css';

/**
 * 
 * @returns A static React component displaying addresses on the footer and Contact Us page
 */
export default function AddressComponent({title, address1, address2}){
    return (
        <div className="addressComponent">
            <h4>{title}</h4>
                <a href={"http://maps.google.com/?q=" + address1 + " " + address2} target="_blank">
                <span>{address1}</span>
                <span>{address2}</span>
            </a>
        </div>
    )
}