import React from 'react';
import './ContentQuoteBlock.css';

/**
 * 
 * @param {string} urlFragment: String element representing the URL fragment for the current component (sets the ID of the current component) 
 * @param {string} backgroundImage: Corresponds to the source of the background image for the current content block
 * @param {string} colorTheme: Represents the color theme of the quote block (limited to values in ColorThemes.js)
 * @param {string} topic: Corresponds to the title blurb (uppercase text above the title) of the quote block
 * @param {string} title: Corresponds to the actual title of the quote block
 * @param {Array} paragraphContent: Array of strings that correspond to the paragraph content of the quote block
 * @param {string} sideQuote: Represents the actual quote in the quote block
 * @param {string} quoteAuthor: Displays the author of the inputted quote
 * @param {string} authorAffiliation: Represents the affiliation of the quote's author (what company does the quoter work at?)
 * @returns A React component that displays a content block of a paragraph content AND a quote (see "About Us" page)
 */
export default function ContentQuoteBlock({urlFragment, backgroundImage, colorTheme, topic, title, paragraphContent, sideQuote, quoteAuthor, authorAffiliation}){

    return (
        <div className="contentQuoteBackground" id={urlFragment} style={{
            backgroundImage: `url('${backgroundImage}')`
        }}>
            <div className="contentQuoteWrapper" id={colorTheme}>
                <h6>{topic}</h6>
                <h2>{title}</h2>

                <div className="contentQuoteSection">
                    <div className="contentQuoteText">
                        {
                            paragraphContent && paragraphContent.map((paragraph, index) => {
                                return (
                                    <p key={index}>{paragraph}</p>
                                )
                            })
                        }
                    </div>
                    <div className='sideQuote'>
                        <h2>"{sideQuote}"</h2>
                        <p className="sideQuoteAuthor">{quoteAuthor}</p>
                        <p className="sideQuoteCompany">{authorAffiliation}</p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}