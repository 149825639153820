import React from "react";
import './PopupDescription.css';

/**
 * 
 * @param {string} categoryName: Represents the name for the given category
 * @param {string} description: Corresponds to the description for a specific category in the Header component
 * @param {string} buttonText: Represents the text that appears on a button, redirecting to a specific page
 * @param {string} buttonRef: Represents the link to a new web page, based on the currently opened category within the Header
 * @returns A React component that is a section of the Header Popup, acting as a description for a certain category of the Header
 */
export default function PopupDescription({categoryName, description, buttonText, buttonRef, closeNavigate}){
    
    return (
        <div className="popupDescription">
            <h2 className="categoryName">{categoryName}</h2>
            <p className="categoryDescription">{description}</p>
            {buttonText && <button className="popupReferences" onClick={() => closeNavigate(buttonRef)}>{buttonText}</button>}
        </div>
    )
}