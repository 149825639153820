import React from "react";
import Markdown from "react-markdown";
import './Certification.css';
import config from "../../config/config";

/**
 * 
 * @param {string} certificationName: Represents the name of a professional certification
 * @param {Array[string]} certificationImages: Corresponds to an array of string elements, in which each string element is the 
 *                                              image source of the logo for a certification
 * @param {string} certificationText: Represents the Markdown text for the description of a professional certification (obtained from Strapi)
 * @returns: A React component that displays the description for one of Niyam's professional certifications (see "About Us" page)
 */
export default function Certification({certificationName, certificationImages, certificationText}){
    return (
        <div className="certificationItem">
            <p className="certificationName">{certificationName}</p>
            <div className="certificationImages">
                {
                    certificationImages && certificationImages.map((image, index) => {
                        const imageURL = config.strapiUrl + image.attributes.url
                        return (
                            <img className="certificationLogo" alt="Certification" key={index} src={imageURL}/>
                        )
                    }) 
                }
            </div>
            <div className="certificationText">
                <Markdown
                    children={certificationText}
                    />
            </div>
        </div>
    )
}