import React from "react";
import './MobileGridContent.css';

/**
 * 
 * @param {string} backgroundImage: Corresponds to the source of the current banner's background image
 * @param {string} topic: Represents the topic content (h6 element) of the current banner 
 * @param {string} title: Corresponds to the title (h2 element) of the current banner
 * @param {string} text: Corresponds to the paragraph content/body text
 * @param {string} referenceText: String element representing the text that appears for a clickable link (E.x., View Services ›)
 * @param {string} reference: React JSX element corresponding to a clickable link; redirects to a document or web page
 * @returns A React component for a mobile version of the "GridContent" component. 
 *            Content and images are organized so that an image is located above text
 */
export default function MobileGridContent({backgroundImage, topic, title, text, referenceText, reference}){
    return (
        <div className="gridBlock">
            <div className="blockBackground" style={{
                backgroundImage: `url('${backgroundImage}')`
            }}>
                <h6>{topic}</h6>
                <h2>{title}</h2>
            </div>
            <div className="block">
                <div className="blockContent">
                    <p>{text}</p>
                    <a href={reference}>
                        {referenceText}
                    </a>  
                </div>
            </div>
            
        </div>
    )
}