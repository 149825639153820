import React from 'react';

export default function TimelineItem({ year, details, position }) {
  return (
    <div className={`timeline-item ${position}`}>
      <div className="content">
        <p className="timeline-year">{year}</p>
        {details.map((detail, index) =>
          typeof detail === 'object' && detail.important ? (
            <p key={index} className="timeline-important">{detail.important}</p>
          ) : (
            <p key={index}>{detail}</p>
          )
        )}
      </div>
    </div>
  );
}
