import React, {useState, useMemo} from "react";
import ResourceEntry from "./resourceEntry/ResourceEntry";
import { getResources } from "../../api/api";
import './ResourceSection.css';
import config from "../../config/config";

/**
 * 
 * @param {string} sectionTitle: Represents the title for a collection of resources under specifically-labeled categories (E.x. Fact Sheet & White Papers)
 * @param {Array[JSON]} resourceCategories: Corresponds to an array of JSON objects obtained from Strapi, in which each JSON contains data about a specific resource
 * @returns: A React component for a section of resources on the "Resources" page 
 */
export default function ResourceSection({sectionTitle, resourceCategories}){

    const [resources, setResources] = useState([])

    async function pullResources(){
        const response = await getResources(resourceCategories)
        setResources(response.data)
    }

    useMemo(() => {
        pullResources()
    }, [])

    return (
        <div className="resourceSection">
            <p className="sectionTitle">{sectionTitle}</p>
            <div className="resourcesGrid">
                {
                    resources && resources.map(resourceEntry => {
                        const resourceURL = config.strapiUrl + resourceEntry.attributes.Document.data.attributes.url;

                        return (
                            <ResourceEntry
                                key={resourceEntry.id}
                                resourceCategory={resourceEntry.attributes.Category}
                                resourceName={resourceEntry.attributes.Name} 
                                resourceDesc={resourceEntry.attributes.Description}
                                resourceLink={resourceURL}
                                />
                        )
                })}
            </div>
        </div>
    )

}