import React, {useState, useMemo} from "react";
import TitleBanner from "../../components/titleBanner/TitleBanner";
import "./Blog.css";
import BlogEntry from "../../components/blogEntry/BlogEntry";
import BlogTags from "../../components/blogTags/BlogTags";
import FeaturedBlogSection from "../../components/featuredBlogSection/FeaturedBlogSection";
import PaginationButtons from "../../components/paginationButtons/PaginationButtons";
import { getAllBlogs, getAllBlogsByName } from "../../api/api";
import './Blog.css';
import titleBackground from '../../assets/images/blogImages/title-background.jpg';
import config from "../../config/config";
/**
 * 
 * @returns: A page corresponding to the main blog page, under the path: '/blog'
 */
export default function Blog(){

    const pageSize = 4

    const [searchInput, setSearchInput] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [pageNumber, setPageNumber] = useState(1)

    const [recentBlogs, setRecentBlogs] = useState([])

    // Featured Topics for the main Blog Page; HR department can choose to edit whenever
    const [sectionOne, setSectionOne] = useState('Client Delivery Services')
    const [sectionTwo, setSectionTwo] = useState('Data Science')
    
    /**
     * Pulls ALL blogs containing the provided search term
     */
    async function searchBlogs(){
        if(searchInput.trim().length > 0){
            const blogResponse = await getAllBlogsByName(searchInput.trim(), pageNumber, pageSize)

            if(blogResponse.data && blogResponse.data.length > 0){
                setSearchResults(blogResponse.data)
            }
            else{
                // Change this later to add a message that there are no more pages
                setSearchResults([])
            }
        }
    }

    /**
     * Pulls the most recent blogs, limited in quantity by "pageSize"
     */
    async function pullRecentBlogs(){
        const pulledBlogs = await getAllBlogs(pageNumber, pageSize)
        if (pulledBlogs.data && pulledBlogs.data.length > 0){
            setRecentBlogs(pulledBlogs.data)
        } else{
            // Change this later to add a message that there are no more pages
            setRecentBlogs([])
        }
    }

    useMemo(() => {
        document.title = "Blog"
        pullRecentBlogs()
    }, [pageNumber || 1])

    useMemo(() => {
        searchBlogs()
    }, [pageNumber])

    return (
        <div className="blogPage">
            <TitleBanner backgroundImage={titleBackground} title="Our Blog"/>
            
            <div className="blogSearch">
                {/**
                 * Performs search request to find all blogs with the relevant search term in its title.
                 */}
                <form onSubmit={(event) => {
                    event.preventDefault()
                    setPageNumber(1)
                    searchBlogs()
                }}>
                    <input className="blogSearchInput" placeholder="Search..." onChange={(event) => setSearchInput(event.target.value)}/>
                </form>
            </div>

            {
                searchResults.length === 0
                ? <div className="blogEntriesWrapper">
                    <div className="recentBlogs">
                        <div className="blogList">

                            {/**
                             * Iterates through all recent blogs in the "recentBlogs" object, rendering a "BlogEntry" component for each item
                             */}
                            {recentBlogs && recentBlogs.map(blogData => {
                                const blogAttributes = blogData.attributes;

                                return (
                                    <BlogEntry
                                        key={blogData.id}
                                        title={blogAttributes.Title}
                                        date={blogAttributes.PostDate}
                                        thumbnail={config.strapiUrl + blogAttributes.Thumbnail.data.attributes.url}
                                        reference={`/blog/${blogData.id}`}
                                        />
                                )
                            })}
                        </div>
                    </div>
                    <PaginationButtons
                        showPrevButton={(pageNumber > 1)}
                        showNextButton={(recentBlogs.length === pageSize) && (recentBlogs[0].attributes)}
                        updatePageNumber={setPageNumber}
                        />

                    <FeaturedBlogSection
                        sectionTopic={sectionOne}
                        pageSize={pageSize}/>
                    <FeaturedBlogSection
                        sectionTopic={sectionTwo}
                        pageSize={pageSize}/>

                </div>
                : <div className="blogSearchResults">
                    <h2 className="searchInputDesc">Search Term: {searchInput}</h2>
                    <div className="recentBlogs">
                        <div className="blogList">

                            {/**
                             * Iterates through all blogs matching the provided search term, rendering a "BlogEntry" component for each item
                             */}
                            {searchResults.map((blogData, index) => {
                                if(blogData.attributes){
                                    const blogAttributes = blogData.attributes;

                                    return (
                                        <BlogEntry
                                            key={blogData.id}
                                            title={blogAttributes.Title}
                                            date={blogAttributes.PostDate}
                                            thumbnail={config.strapiUrl + blogAttributes.Thumbnail.data.attributes.url}
                                            reference={`/blog/${blogData.id}`}
                                            />
    
                                    )
                                }
                                else{
                                    return (
                                        <div key={index}/>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    <PaginationButtons
                        showPrevButton={(pageNumber > 1)}
                        showNextButton={(searchResults.length === pageSize) && (searchResults[0].attributes)}
                        updatePageNumber={setPageNumber}
                        />
                </div>
            }
            

            <BlogTags/>
        </div>
    )
}