import React from "react";
import './PageBlurb.css'

/**
 * 
 * @param {string} blurb: Represents the blurb (text) appearing at the beginning of certain web pages 
 * @returns A React component of a blurb, describing the content for a given page, and a horizontal divider; component appears at top of page
 */
export default function PageBlurb({blurb}){
    return (
        <div className="pageBlurbComponent">
            <div className="blurbDiv">
                <div className="pageBlurb">
                    <h5 className="blurbContent">{blurb}</h5>
                </div>
            </div>
            <div className="centeredBar"><div className="horizontalDivider"></div></div>
        </div>
    )
}