import React from 'react'
import './SmallBlogEntry.css';

/**
 * This component represents a smaller blog entry, which is visible at the "Blog" main page. The blog entry contains only a title and a post date.
 * 
 * @param {string} title: Represents the title of a specific blog entry
 * @param {string} date: Represents the date when the specific blog entry was posted
 * @param {string} reference: Corresponds to a link that represents a specific blog article (containing ALL content for that specific article) 
 * @returns: A React component that serves as a miniature blog entry that is also visible on the "Blog" main page;
 *              Similar to the "BlogEntry" component, but smaller and without a thumbnail
 */
export default function SmallBlogEntry({title, date, reference}) {

    const maxLength = 30

    if(title.length > maxLength){
        title = title.substring(0, maxLength+1) + '...'
    }

    return (
        <div className="smallBlogEntryContainer">
            <a href={reference} className="smallBlogEntry">
                <h2 className="smallBlogEntryTitle">{title}</h2>
                <p className="smallBlogEntryDate">{date}</p>            
            </a>
        </div>
    )
}
