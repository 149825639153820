import { ResourceCategory } from "../models/ResourceCategory";

var categoryMap = new Map();

/**
 * Populates "categoryMap" with icon fonts that correspond with certain ResourceCategory objects
 */
export function generateCategoryMap(){
    categoryMap.set(ResourceCategory.FactSheet, 'h')
    categoryMap.set(ResourceCategory.WhitePaper, 'i')
    categoryMap.set(ResourceCategory.Brochure, '\uE01F')
    categoryMap.set(ResourceCategory.Presentation, '\uE0E9')
}

/**
 * 
 * @param {ResourceCategory} resourceCategory: Takes in a ResourceCategory object (see ResourceCategory.js) 
 * @returns: A string value corresponding to the icon for a specific ResourceCategory
 */
export function categoryToIcon(resourceCategory) {
    return categoryMap.get(resourceCategory)
}