import React from "react";
import { useNavigate } from 'react-router-dom';
import { DisplayTypes } from "../../../models/DisplayTypes";

/**
 * 
 * @param {DisplayTypes} currentDisplay: Represents the current display type of the user's viewport 
 * @returns A React component of the "ABOUT US" section of the footer (different depending on the current display type)
 */
export default function AboutUsSection({currentDisplay}){
    const navigate = useNavigate()

    return (
        <>
            <button className="columnTitle" onClick={() => navigate("/about-us")}>ABOUT US</button>

            {/**
             * If in computer display, show the "Our Story" and "Meet the Team" link in the footer
             */}
            {(currentDisplay === DisplayTypes.Computer) && 
            <>
                <button className="columnChild" onClick={() => navigate("/about-us")}>
                    Our Story
                </button>
                <button className="columnChild" onClick={() => navigate("/our-team")}>
                    Meet the Team
                </button>
            </>
            }
        </>
    )
}