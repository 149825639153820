import React, { useState } from "react"
import { HeaderCategory } from "../../../models/HeaderCategory";
import './PopupLinks.css';

/**
 * 
 * @returns A React component representing the various links that users can click to redirect to other pages within the website
 */
export default function PopupLinks({togglePopup, closeNavigate}){
    const [selected, setSelected] = useState([])

    const handleClick = (category) => {
        category !== "Blog" ? togglePopup(category) : closeNavigate("/blog")
        setSelected(category)
    }

    return (
        <div className="popupLinks">
            <ul className="linkNames">
                <li>
                    <button className={selected === "Capabilities" ? "selected" : ""} onClick={() => handleClick(HeaderCategory.Capabilities)}>Capabilities</button>
                </li>
                <li>
                    <button className={selected === "Industries" ? "selected" : ""} onClick={() => handleClick(HeaderCategory.Industries)}>Industries</button>
                </li>
                <li>
                    <button className={selected === "About Us" ? "selected" : ""} onClick={() => handleClick(HeaderCategory.AboutUs)}>About Us</button>
                </li>
                <li>
                    <button className={selected === "Careers" ? "selected" : ""} onClick={() => handleClick(HeaderCategory.Careers)}>Careers</button>
                </li>
                <li>
                    <button className={selected === "Blog" ? "selected" : ""} onClick={() => handleClick("Blog")}>Blog</button>
                </li>
            </ul>
        </div>
    )
}