import React from "react";
import './BlogEntry.css';

/**
 * This component represents a UI block that serves as a blog entry, which is visible at the "Blog" main page. The blog entry contains only a title,
 * a post date, and a thumbnail.
 * 
 * @param {string} title: Represents the title of a specific blog entry
 * @param {string} date: Represents the date when the specific blog entry was posted
 * @param {string} thumbnail: Corresponds to the link of an image that serves as a thumbnail for a blog entry
 * @param {string} reference: Corresponds to a link that represents a specific blog article (containing ALL content for that specific article) 
 * @returns: A React component that serves as a blog entry that is visible on the "Blog" main page; clicking it directs to that blog article itself
 */
export default function BlogEntry({ title, date, thumbnail, reference}){

    const maxLength = 60;

    if(title.length > maxLength){
        title = title.substring(0, maxLength + 1) + '...'
    }
    
    return (
        <div className="blogEntryContainer">
            <a className="blogEntry" href={reference}>
                <img src={thumbnail} alt="blogImage" className="blogThumbnail"/>
                <div className="blogContent">
                    <h5 className="blogEntryTitle">{title}</h5>
                    <p className="blogEntryDate">{date}</p>             
                </div>
            </a>
        </div>
        
    )
}