import React, {useState, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import './CapabilitiesPages.css';
import TitleBanner from "../../components/titleBanner/TitleBanner";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb";
import CapabilitiesBlock from "../../components/capabilitiesBlock/CapabilitiesBlock";
import QuoteBlock from "../../components/quote/QuoteBlock";
import ConclusionBanner from "../../components/conclusionBanner/ConclusionBanner";
import PartnersBanner from "../../components/partnersBanner/PartnersBanner";
import ServicesGrid from "../../components/servicesGrid/ServicesGrid";
import MiniCapabilitiesBlock from "../../components/capabilitiesBlock/MiniCapabilitiesBlock";
import { getDisplayType } from "../../controllers/screen-controller.js";
import { TextPosition } from "../../models/TextPosition";
import { ColorThemes } from "../../models/ColorThemes";
import { PageTypes } from "../../models/PageTypes";
import { getSpecificResource } from "../../api/api.js";
import titleBackground from "../../assets/images/dataScienceImages/title-background.png";
import visualizationIcon from "../../assets/images/capabilitiesIcons/visualization-icon.png";
import analyticsIcon from "../../assets/images/homeImages/analytics-icon.png";
import monitoringIcon from "../../assets/images/capabilitiesIcons/monitoring-icon.png";
import servicesGridBackground from "../../assets/images/dataScienceImages/services-grid-background.png";
import dataService from "../../assets/images/dataScienceImages/data-service.png";
import insightService from "../../assets/images/dataScienceImages/insight-service.png";
import governanceService from "../../assets/images/dataScienceImages/governance-service.png";
import processingService from "../../assets/images/dataScienceImages/processing-service.png";
import dashboardService from "../../assets/images/dataScienceImages/dashboard-service.png";
import analyticsService from "../../assets/images/dataScienceImages/analytics-service.png";
import byobService from "../../assets/images/dataScienceImages/byob-service.png";
import conclusionBackground from "../../assets/images/dataScienceImages/conclusion-background.png";

/**
 * 
 * @returns A page corresponding to the Data Science page of the Niyam website, under the path "/data-science"
 */
export default function DataScience(){
    const navigate = useNavigate()
    const [currentDisplay, setCurrentDisplay] = useState()
    const [dataScienceURL, setDataScienceURL] = useState("")
    const [predictiveAnalyticsURL, setPredictiveAnalyticsURL] = useState('')

    /**
     * Performs GET request to obtain relevant PDF sources for the current page
     */
    async function getRelevantResources(){
        setDataScienceURL(await getSpecificResource("nit_datascience.pdf"))
        setPredictiveAnalyticsURL(await getSpecificResource("nit_predictiveanalytics.pdf"))
    }

    useMemo(() => {
        document.title = "Data Science"

        setCurrentDisplay(getDisplayType())
        getRelevantResources()
    }, [])

    return (
        <div className="dataScienceContainer">
            <TitleBanner backgroundImage={titleBackground} title="Data Science - AI/ML, Analytics & Management"/>
        
            <div className="factSheet">
                <TopicBlurb 
                    colorTheme={ColorThemes.Light}
                    topic="Clear Insights. Real Answers." 
                    title="A 360º APPROACH TO DATA"/>
                <p className="factSheetText">
                    Niyam understands the value of big data in today’s dynamic, evolving technological ecosystem. 
                    Combined with our cloud engineering, architecture design and IT security offerings, we deliver a comprehensive approach to data management that can be easily tailored to suit the needs of any size business. 
                    Our experts have years of hands-on experience managing every aspect of the data process - from planning and design through deployment, analysis and monitoring - ensuring you always get clear, relevant, actionable insights.
                </p>
                <button onClick={() => navigate(dataScienceURL)}>
                    View Our Data Science Factsheet
                </button>
            </div>
            <div className="dataScienceCapabilities">
                <CapabilitiesBlock 
                    currentDisplay={currentDisplay}
                    icon={visualizationIcon}
                    topic="Visualization" 
                    textBlocks={[
                        "Access your data exactly the way you want by choosing the tool that fits your specific needs.",
                        "Easily integrate with off-the-shelf visualization software such as Tableau or Power BI, or deploy custom-built dashboards tailored to your exact specifications"
                    ]}
                    textPosition={TextPosition.Right}
                    />

                <CapabilitiesBlock 
                    currentDisplay={currentDisplay}
                    icon={analyticsIcon}
                    topic="Precision Analytics" 
                    textBlocks={[
                        "Granular, deep-diving analytic solutions deliver the clearest, most revealing view of your data.",
                        "Natural Language Processing (NLP), custom algorithms, data mining and predictive modeling strategies all work in harmony to reveal deep, relevant, timely insights"
                    ]}
                    textPosition={TextPosition.Left}
                    reference={
                        <a href={predictiveAnalyticsURL}>Download the Predictive Analytics PDF ›</a>
                    }
                    />

                <CapabilitiesBlock
                    currentDisplay={currentDisplay} 
                    icon={monitoringIcon} 
                    topic="Continuous Monitoring" 
                    textBlocks={[
                        "Synchronized. Harmonized. Ready for maximum intelligence.",
                        "Powerful data modeling and cleansing strategies add clarity and meaning to your data, paving the way for extracting maximum business intelligence and insights"
                    ]}
                    textPosition={TextPosition.Right}
                    />
            </div>

            <ServicesGrid
                backgroundImage={servicesGridBackground}
                colorTheme={ColorThemes.Dark}
                topic="DATA SERVICES"
                title="Features & Benefits"
                serviceComponents={[
                    <MiniCapabilitiesBlock 
                        iconImage={dataService}
                        topic="Unified Data" 
                        text="Our state-of-the-art data management platform unifies all your diverse data sources for maximum interoperability"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={insightService}
                        topic="Meaningful Insight" 
                        text="Powerful data modeling and cleansing strategies add clarity and meaning to your data for incredibly accurate, reliable intelligence"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={governanceService} 
                        topic="Quality Governance" 
                        text="Our dynamic, iterative process continuously improves governance protocols to ensure data is accurate, reliable and accessible"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={processingService}
                        topic="Power Processing" 
                        text="Simplify and organize structured and unstructured data using Natural Language Processing (NLP), custom algorithms and ontologies"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={dashboardService}
                        topic="Custom Dashboard" 
                        text="Bespoke analytics and visualization solutions built to your specifications"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={analyticsService} 
                        topic="Powerful Analytics" 
                        text="Extract maximum value from your data through statistical computing, data mining, predictive modeling and decision support analytics"/>,
                    <MiniCapabilitiesBlock 
                        iconImage={byobService}
                        topic="BYOB Visualization" 
                        text="Add self-service BI capabilities to disparate, high-volume data sources for new insights"/>,
                ]}
                />

            <QuoteBlock
                colorTheme={ColorThemes.Light}
                quote="Niyam IT displays high service levels, as evidenced by their aggressiveness in handling help desk tickets."
                author="Sunia Akbary"
                authorAffiliation="Partner, IBM Global Services"/>

            <ConclusionBanner
                backgroundImage={conclusionBackground}
                title="MODERNIZE YOUR DATA STRATEGY"
                body="Let our experts help you grow into the future with a custom-built solution"
                buttonRef="/contact-us"
                buttonText="Request a consultation"/>

            <PartnersBanner pageType={PageTypes.DataScience} />

        </div>
    )
}
