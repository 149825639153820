import React from "react";
import { ColorThemes } from "../../models/ColorThemes";

import './FeaturedClient.css';
import { DisplayTypes } from "../../models/DisplayTypes";

/**
 * 
 * @param {DisplayTypes} currentDisplay: Represents the current display type for the user's viewport
 * @param {string} backgroundImage: Represents the source for the background image of the current content block
 * @param {ColorThemes} colorTheme: Optional parameter for the color theme; corresponds to the color theme of the text for this content block 
 *                                  (limited between values in ColorThemes.js)
 * @param {string} client: Represents the client that is displayed in this content block
 * @param {string} department: Represents the department of the client (also displayed within the content block)
 * @param {string} text: Text corresponding to the paragraph content
 * @param {string} blogLink: Represents the link to related blogs 
 * @returns A React component that displays a content block for the "Featured Client" sections of the "Industries" page
 */
export default function FeaturedClient({currentDisplay, backgroundImage, colorTheme = ColorThemes.Dark, client, department, text, blogLink}){
    return (
        <div className="featuredClient" style={{
            backgroundImage: `url('${backgroundImage}')`,
        }}>
            <div className="featuredClientContent" id={colorTheme}>
                <h6 className="topicItem">FEATURED CLIENT</h6>
                {(currentDisplay === DisplayTypes.Tablet) 
                    ? <div>
                        <h2 className="client">{client}</h2>
                        <h2 className="department"><span className="departmentSpan">{department}</span></h2>
                    </div>
                
                    : <div>
                        <h2 className="client">{client} <span className="departmentSpan"> | {department} </span> </h2>
                    </div>
                }
                <p className="featuredClientText">{text}</p>

                <a className="blogLink" href={blogLink}>
                    Read more case studies ›
                </a>
                
            </div>
        </div>
    )
}