import React from "react";
import { categoryToIcon } from "../../../controllers/resource-controller.js";
import './ResourceEntry.css';

/**
 * 
 * @param {ResourceCategory} resourceCategory: Corresponds to the category-type of the current Resource item
 * @param {string} resourceName: Represents the name of the resource, as it is displayed on the "Resources" page
 * @param {string} resourceDesc: Optional string corresponding to a description of the current Resource item
 * @param {string} resourceLink: Represents the link to a PDF copy of the emphasized resource  
 * @returns: A React component that displays a given resource item (stored in Strapi), which can be clicked to open a copy of the resource item
 */
export default function ResourceEntry({resourceCategory, resourceName, resourceDesc, resourceLink}){
    
    return (
        <div className="resourceEntry">
            <a href={resourceLink} className="resourceLink">
                <div className="resourceContent">
                    <h6 className="resourceCategory"><span className="categoryIcon">{categoryToIcon(resourceCategory)}</span> {resourceCategory}</h6>
                    <h2 className="resourceName">{resourceName}</h2>
                    <p className="resourceDesc">{resourceDesc}</p>
                </div>

                <div className="downloadSection">
                    <p className="downloadText">OPEN</p>
                </div>
            </a>
            
        </div>
    )
}