import React, {useState, useMemo} from 'react'
import { getBlogTags } from '../../api/api';
import "./BlogTags.css";

/**
 * 
 * @returns A React component of ALL blog tags that exist in Strapi (under the "Blog Tag" collection type)
 */
export default function BlogTags(){

  const [blogTopics, setBlogTopics] = useState([])

  /**
   * Pulls all tags through a GET request
   */
  async function pullAllTags(){
    const tags = await getBlogTags()
    if(tags.data){
      setBlogTopics(tags.data)
    }
  }

  useMemo(() => {
    pullAllTags()
  }, [])

  return (
    <div className='blogTags'>
        <h1 className="blogTopicsTitle">All Topics</h1>
        <div className='blogTagsContent'>
          
          {/**
           * Maps through ALL elements in "blogTopics" to create buttons for each blog tag. Each button directs to a page
           * of all blogs under that specific tag
           */}
          {
            blogTopics && blogTopics.map(topic => {
              const topicAttributes = topic.attributes

              return (
                <div className="tagBox" key={topic.id}>
                  <a href={`/blog/tags/${topicAttributes.TagName}`} className="tagBoxRef">
                    {topicAttributes.TagName}
                  </a>
                </div>
              )
            })
          }

        </div>

    </div>
  )
}
