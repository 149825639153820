export const PageTypes = Object.freeze({
    Home: 'Home',
    DataScience: 'Data Science',
    GIS: 'GIS',
    CloudEngineering: 'Cloud Engineering',
    SystemModernization: 'System Modernization - Agile, DevSecOps, Low-code/No-code & RPA',
    Industries: 'Industries',
    AboutUs: 'About Us',
    OurClients: 'Our Clients',
    OurVendors: 'Our Vendors'
})
