import React from "react";
import './PopupNavigation.css';

/**
 * 
 * @param {Array[JSON]} pageLinks: Corresponds to the name AND links of all web pages under a certain Header category
 * @returns A React component that is part of the Header Popup, displaying all links under a certain Header category
 */
export default function PopupNavigation({pageLinks}){
    return (
        <div className="pageList">
            <div className="pageNames">
                {pageLinks && pageLinks.map((page, index) => {
                    return (
                        <div className="pageName" key={index}>
                            <a className="pageLinkRef" href={page.address}>
                                <p className="pageLinkTitle">{page.name}</p>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}