import React from "react";
import { useNavigate } from 'react-router-dom';
import './ConclusionBanner.css';

/**
 * 
 * @param {string} backgroundImage: Corresponds to the link of the background image for the content block
 * @param {string} title: Represents the title of this content block (Conclusion Banner), displayed in large heading text
 * @param {string} body: Optional string input that represents body text of the content block (Conclusion Banner)
 * @param {string} buttonRef: Represents the link that the banner button redirects to
 * @param {string} buttonText: Corresponds to the text appearing on the button (within the Conclusion Banner)
 * @returns A React component called the "Conclusion Banner", which is a banner appearing at the end of each page
 *          The "ConclusionBanner" component consists of large text and a button, along with a background image
 */
export default function ConclusionBanner({backgroundImage, title, body, buttonRef, buttonText}){
    const navigate = useNavigate()

    return (
        <div className="conclusionBanner" style={{
            backgroundImage: `url('${backgroundImage}')`
        }}>
            <h3 className="conclusionTitle">{title}</h3>
            {body && <p className="conclusionBody">{body}</p>}
            {buttonText && <button onClick={() => navigate(buttonRef)}>{buttonText}</button>}
        </div>
    )
}
