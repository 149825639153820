import React, { useState, useMemo } from 'react';
import { getContractVehicles } from '../../api/api.js';
import Markdown from 'react-markdown';
import './ContractVehicles.css';
import ContractVehicleEntry from '../contractVehicleEntry/ContractVehicleEntry.js';
import config from "../../config/config";

/**
 * 
 * @returns A React component corresponding to ALL contract vehicle entries, appearing at the contract vehicles section of the "About Us" page (navigate to '/about-us#contracts').
 *          Clicking the "Learn More" button displays a dropdown to provide detailed information on a specfic contract vehicle
 */
export default function ContractVehicles(){

    const [contractVehicles, setContractVehicles] = useState([])
    const [selectedContractVehicle, setSelectedContractVehicle] = useState(null)
    const [content, setContent] = useState()
    
    async function fetchContractVehicles(){
        const contractVehicleJSON = await getContractVehicles()

        if(contractVehicleJSON.data){
            setContractVehicles(contractVehicleJSON.data)
        }
    }

    function changeDropdownContent(newContent, contractVehicleId){
        if (contractVehicleId === selectedContractVehicle){
            setSelectedContractVehicle(null)
            setContent(null)
        }
        else{
            setContent(newContent)
            setSelectedContractVehicle(contractVehicleId)
        }
    }

    useMemo(() => {
        fetchContractVehicles();
    }, [])

    return (
        <div className='contractsContainer'>
            <div className="contractsContent" id="contracts">
                <div className="contractsDesc">
                    <h2>Our Contract Vehicles</h2>
                    <p>We are vetted by federally approved agencies.</p>
                    <p>Our contract vehicles and easy-to-use mechanisms make it easy for federal agencies to procure our solutions and services.</p>
                </div>

                <div className="contractVehicles">
                    {
                        contractVehicles && contractVehicles.map((contractVehicle) => {
                            const imageURL = config.strapiUrl+contractVehicle.attributes.Logo.data.attributes.url;

                            return (
                                <ContractVehicleEntry
                                    key={contractVehicle.id}
                                    title={contractVehicle.attributes.Title}
                                    contractNumber={contractVehicle.attributes.ContractNumber}
                                    imageURL={imageURL}
                                    dropdownInfo = {contractVehicle.attributes.DropdownInfo}
                                    contractVehicleId = {contractVehicle.id}
                                    dropdownCallback={changeDropdownContent}
                                />
                            )
                        })
                    }
                </div> 
                {selectedContractVehicle && content &&
                    <div className="dropdownBox">
                        <Markdown children={content} />
                    </div>
                }
            </div>
        </div>
    )

}