import React from "react";
import { useNavigate } from "react-router-dom";
import './RighthandButtons.css';

/**
 * 
 * @returns A React component corresponding to the right-hand buttons on the Header ("Resources" and "Contact Us")
 */
export default function RighthandButtons(){
    const navigate = useNavigate()

    return (
        <div className="righthandButtons">
            <button className="resourcesRef" onClick={() => navigate("/resources")}>
                Resources
            </button>
            <button className="contactUsRef" onClick={() => navigate("/contact-us")}>
                Contact Us
            </button>
        </div>
    )
}