import React from "react";
import TopicBlurb from "../../components/blurbBanners/TopicBlurb.js";
import MiniCapabilitiesBlock from "../../components/capabilitiesBlock/MiniCapabilitiesBlock.js";
import { ColorThemes } from "../../models/ColorThemes.js";

/**
 * 
 * @returns A page corresponding to the "CompanyValues" page
 */
export default function CompanyValues(){
   
    return (
    <div className="companyValuesGrid" id="careers">
        <TopicBlurb
            colorTheme={ColorThemes.Light}
            topic="WORK WITH US"
            topicBlurb="Niyam IT prides itself on being a small, close-knit team of experts. Around here, you have the freedom to work how you want, with opportunities to offer new ideas, expand your skillset and make your mark on the IT industry."
            title="We offer a generous benefit package to all employees, including:"
        />
        <div className="workWithUsBlocks">
            <MiniCapabilitiesBlock
                colorTheme={ColorThemes.Light}
                iconFont={'\uE72b'} 
                topic="PTO" 
                text="We value work-life balance and will pay you to achieve it. We offer paid time off and an array of holidays."
            />
            <MiniCapabilitiesBlock
                colorTheme={ColorThemes.Light}
                iconFont={'\uE623'} 
                topic="Training" 
                text="See a class you want to take? Have your eye on a certain certificate? At Niyam IT, we realize we're only as good as our employees and we work with you to become better. All you have to do is ask."
            />
            <MiniCapabilitiesBlock
                colorTheme={ColorThemes.Light}
                iconFont={'\uE634'} 
                topic="Full Health Benefits" 
                text="We believe in taking care of you and your dependents. Our health, vision and dental plans provide excellent coverage are are always affordable."
            />
            <MiniCapabilitiesBlock
                colorTheme={ColorThemes.Light}
                iconFont={'\uE689'} 
                topic="Special Events" 
                text="We like to keep you happy. There's always something going on: happy hours, holiday parties, soccer tournaments and summer picnics."
            />
            <MiniCapabilitiesBlock
                colorTheme={ColorThemes.Light}
                iconFont={'\uE691'} 
                topic="Future Planning" 
                text="We're proud to offer a retirement plan for all eligible employees. With employer matching and online management, we make it easy to plan for the future."
            />
        </div>
        <button onClick={() => window.location.href = "https://niyamit.bamboohr.com/careers"}>See open positions</button>
    </div>
    )
}
