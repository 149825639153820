import React from "react";

import { Outlet } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import './Layout.css'

export default function Layout(){
    return (
        <div className="pageWrapper">
            <Header/>
            <div className="pageOutlet">
                <Outlet/>
            </div>
            <Footer/>
        </div>
    )
}
