import React from 'react';

export default function TimelineHeader({ title, subtitle }) {
  return (
    <div className="timeline-header">
      <h3>{title}</h3>
      <h2>{subtitle}</h2>
    </div>
  );
}
