import React from "react";
import Slider from "react-slick";
import SlideshowItem from "./SlideshowItem";

import './SlideshowComponent.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import config from "../../config/config";

/**
 * 
 * @param {Array[JSON]} slideshowItems: Array of JSON objects from Strapi corresponding to content for each Slideshow item (Home Page), such as the markdown content,
 *                                      and the background image  
 * @returns A React component of the slideshow (of news) at the top of the home page
 */
export default function SlideshowComponent({slideshowItems}){

    /**
     * The below "settings" variable corresponds to the settings of the slideshow ribbon in the Home Page
     */
    var settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };

    return (
        <div className="homeSlideshow">

            {/**
             * If there is only ONE slideshow item
             */}
            {
                (slideshowItems.length > 1) 
                    && <Slider {...settings} >
                        {slideshowItems.map(slideshowItem => {
                            
                            const backgroundImage = config.strapiUrl+slideshowItem.attributes.Background.data.attributes.url
                            
                            return (
                                <SlideshowItem 
                                    key={slideshowItem.id} 
                                    backgroundImage={backgroundImage}
                                    bannerContent={slideshowItem.attributes.SlideshowContent.Content}
                                    />
                            )
                        })}
                    </Slider>
            }

            {/**
             * If there are MULTIPLE slideshow items
             */}
            {
                (slideshowItems.length === 1) && 
                    <SlideshowItem 
                        backgroundImage={config.strapiUrl+slideshowItems[0].attributes.Background.data.attributes.url}
                        bannerContent={slideshowItems[0].attributes.SlideshowContent.Content}
                        />
            }
        </div>
        
    )
}
