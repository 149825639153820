import config from '../config/config.js'

/**
 * 
 * @param {Array[string]} sortParams: Array of string elements corresponding to the order in which Strapi content
 *                                      should be organized when pulled from an API call 
 * @returns: A string of URL search parameters that orders search results when pulled from Strapi 
 */
function generateSortURL(sortParams){
    var sortURL = ""
    for(var i=0;i<sortParams.length;i++){
        sortURL += `&sort[${i}]=${sortParams[i]}`
    }
    return sortURL
}

/**
 * 
 * @param {Array[JSON]} filterParams: Array of string elements representing how data should be filtered when pulled from Strapi
 * @returns: A string URL of filter parameters to pull certain content from Strapi
 */
function generateFilterURL(filterParams){
    var filterURL = ""
    for(var i=0;i<filterParams.length;i++){
        filterURL += `&filters[${filterParams[i].attribute}][$in][${i}]=${filterParams[i].value}`
    }
    return filterURL
}

function generatePaginationURL(pageNumber, pageSize){
    return `&pagination[page]=${pageNumber}&pagination[pageSize]=${pageSize}`
}

/**
 * 
 * @param {string} path: Corresponds to the API path (generated by strapi) to retrieve certain information
 * @param {Array[string]} paramGroups: Represents an array of strings for additional search parameters
 * @param {boolean} populateAll: Represents whether or not ALL data should be populated (default value of: true) 
 * @returns: Some JSON data stored in strapi
 */
export async function executeGet(path, paramGroups){
    try{
        var responseRoute = `${config.strapiUrl}/api/${path}?populate=*`

        for(var i=0;i<paramGroups.length;i++){
            responseRoute+=`${paramGroups[i]}`
        }

        const response = await fetch(responseRoute, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${config.strapiApiKey}`
            }
        })
    
        return (await response.json())
    }
    catch(err){
        console.log(err)
        return {}
    }
}

// ===== GET Requests =====
export async function getSlideshow(){
    const sortURL = generateSortURL(['SlideshowContent.Order'])
    return (await executeGet('home-slideshows', [sortURL]))
}

export async function getHomeBanners(){
    const sortURL = generateSortURL(['BannerInfo.Order'])
    return (await executeGet('home-banners', [sortURL]))
}

export async function getPartnerBanners(pageType){
    const filterParams = [
        {attribute: 'PageLocation', value: pageType}
    ]
    const filterURL = generateFilterURL(filterParams) 
    return (await executeGet('partner-banners', [filterURL]))
}

export async function getTeamMembers(){
    const sortURL = generateSortURL(['Order'])
    return (await executeGet('team-members', [sortURL]))
}

export async function getContractVehicles(){
    const sortURL = generateSortURL(['Order'])
    return (await executeGet('contract-vehicles', [sortURL]))
}

export async function getResources(resourceCategories){
    const filterParams = []
    for(var i=0;i<resourceCategories.length;i++){
        filterParams.push(
            {attribute: 'Category', value: resourceCategories[i]}
        )
    }
    const filterURL = generateFilterURL(filterParams)
    const sortURL = generateSortURL(['Order'])

    return (await executeGet('resource-entries', [sortURL, filterURL]))
}

export async function getSpecificResource(fileName){
    const filterURL = `&filters[Document][name]=${fileName}`
    const responseJSON = (await executeGet('resource-entries', [filterURL]))

    if(responseJSON.data && responseJSON.data.attributes){
        return config.strapiUrl + responseJSON.data[0].attributes.Document.data.attributes.url
    }
    else{
        return `/resources`
    }

}

export async function getCertifications(){
    const sortURL = generateSortURL(['Order'])
    return (await executeGet('professional-certifications', [sortURL]))
}

export async function getBlogTags(){
    return (await executeGet('blog-tags', []))
}

export async function getAllBlogsByName(blogName, pageNumber, pageSize){
    const sortURL = generateSortURL(['PostDate:desc'])
    const filterURL = `&filters[Title][$containsi]=${blogName}`
    const paginationURL = generatePaginationURL(pageNumber, pageSize)

    return (await executeGet('blog-entries', [filterURL, sortURL, paginationURL]))
}

export async function getAllBlogsByTag(tagName, pageNumber, pageSize){
    const sortURL = generateSortURL(['PostDate:desc', 'id'])
    const filterURL = `&filters[BlogTags][TagName][$in]=${tagName}`
    const paginationURL = generatePaginationURL(pageNumber, pageSize)

    return (await executeGet('blog-entries', [sortURL, filterURL, paginationURL]))
}

export async function getAllBlogs(pageNumber=0, pageSize=0){
    const sortURL = generateSortURL(['PostDate:desc'])
    
    if(pageNumber === 0 && pageSize ===0){
        return (await executeGet('blog-entries', [sortURL]))
    }

    const paginationURL = generatePaginationURL(pageNumber, pageSize)
    return (await executeGet('blog-entries', [sortURL, paginationURL]))
}

export async function getBlogByID(blogID){
    return (await executeGet(`blog-entries/${blogID}`, []))
}

export async function getBambooData(){
    try {
        const response = await fetch("https://niyamit.bamboohr.com/careers/list")
        if (!response.ok) throw new Error(`Response status: ${response.status}`)
        return response.json()
    } catch (error) {
      console.error(error.message)
    }
}

// ===== POST Requests =====
export async function composeMessageRequest(name, company, email, phone, message, contactMe){
    const responseRoute = `${config.strapiUrl}/api/send-message`
    const response = await fetch(responseRoute, {
        method: 'POST',
        body: JSON.stringify({
            name: name,
            company: company,
            email: email,
            phone: phone,
            message: message,
            contactMe: contactMe
        }),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${config.strapiMessageKey}`
        },
    })

    return response
}
