import React, { useState, useMemo } from "react";
import PopupNavigation from "./popupNavigation/PopupNavigation";
import PopupDescription from "./popupDescription/PopupDescription";
import AdditionalSource from "./additionalSource/AdditionalSource";
import { HeaderCategory } from "../../../models/HeaderCategory";
import { getSpecificResource } from "../../../api/api";

import './PopupDisplay.css';

/**
 * 
 * The PopupDisplay is composed of three distinct UI componets: 
 *      1. PopupDescription (left side of popup display)
 *      2. PopupNavigation (middle of popup display)
 *      3. AdditionalSource (right side of popup display)
 * 
 * @param {HeaderCategory} headerCategory: Corresponds to a specific HeaderCategory (see "models" folder) value, which alters the display of the Header Popup 
 * @returns A React component that acts as a popup whenever the user clicks on a Header link; appearance of the component depends on "headerCategory"
 */
export default function PopupDisplay({headerCategory, closeNavigate}){

    const [capabilitiesURL, setCapabilitiesURL] = useState('')

    /**
     * Performs GET request to obtain the url to "nit_capabilities.pdf"
     */
    async function getRelevantResources(){
        setCapabilitiesURL(await getSpecificResource('nit_capabilities.pdf'))
    }

    useMemo(() => {
        getRelevantResources()
    }, [])

    return (
        <div className="popupDisplay">
            {(headerCategory === HeaderCategory.Capabilities) &&
                <div className="displayWrapper">
                    <PopupDescription
                        categoryName="Capabilities"
                        description="Our precision-crafted IT solutions can transform every aspect of your operation."
                        buttonText="Overview"
                        buttonRef="/capabilities"
                        closeNavigate={closeNavigate}
                        />
                    <PopupNavigation pageLinks={[
                        {name: 'Data Science - AI/ML, Analytics & Management', address: '/data-science'},
                        {name: 'Geospatial Business Intelligence, Situational Awareness', address: '/gis'},
                        {name: 'Cloud Engineered Solutions (IaC) - Zero-trust Architecture', address: '/cloud-engineering'},
                        {name: 'System Modernization - Agile, DevSecOps, Low-code/No-code & RPA', address: '/devsecops'}
                    ]}/>
                    <AdditionalSource
                        sourceCategory="Core Capabilities"
                        description="Learn about Niyam's core capabilities, key differentiators, clients, and partners."
                        buttonText="Download our Capability Statement ›"
                        buttonRef={capabilitiesURL}
                        closeNavigate={closeNavigate}
                        />
                </div>
            }
            {(headerCategory === HeaderCategory.Industries) &&
                <div className="displayWrapper">
                    <PopupDescription
                        categoryName="Industries"
                        description="Our domain expertise allows us to deploy critical technology to select industries."
                        buttonText="See Industries"
                        buttonRef="/industries"
                        closeNavigate={closeNavigate}
                        />
                    <PopupNavigation pageLinks={[
                        {name: 'Emergency Preparedness & Response', address: '/industries#riskanalysis'},
                        {name: 'Natural Resource Management', address: '/industries#resourcemgmt'},
                        {name: 'Law Enforcement & Justice', address: '/industries#justice'},
                        {name: 'Health IT', address: '/industries#publichealth'},
                        {name: 'Global Citizen Services', address: '/industries#intaffairs'}
                    ]}/>
                    <AdditionalSource
                        sourceCategory="Outcomes"
                        description="Our custom solutions have been critical to the success of many public- and private-sector projects."
                        buttonText="See our case studies ›"
                        buttonRef="/blog"
                        closeNavigate={closeNavigate}
                        />
                </div>
            }
            {(headerCategory === HeaderCategory.AboutUs) &&
                <div className="displayWrapper">
                    <PopupDescription
                        categoryName="About Us"
                        description="Our name says it all: orderly, consistent, systematic, governed by rules."
                        buttonText="Learn About Us"
                        buttonRef="/about-us"
                        closeNavigate={closeNavigate}
                        />
                    <PopupNavigation pageLinks={[
                        {name: 'Our Story', address: '/about-us'},
                        {name: 'Social Responsibilities', address: '/about-us#socialresp'},
                        {name: 'Certifications', address: '/about-us#certifications'},
                        {name: 'Contract Vehicles', address: '/about-us#contracts'},
                        {name: 'Meet the Team', address: '/our-team'}
                    ]}/>
                    <AdditionalSource
                        sourceCategory="Careers"
                        description="We're always looking to grow our family of passionate, skilled professionals. Ready to join a winning team?"
                        buttonText="Employee Benefits ›"
                        buttonRef="/about-us#careers"
                        closeNavigate={closeNavigate}
                        />
                </div>
            }
            {(headerCategory === HeaderCategory.Careers) &&
                <div className="displayWrapper">
                    <PopupDescription
                        categoryName="Careers"
                        description="Join Niyam IT and find a job that suites your goals"
                        buttonText="View Careers"
                        buttonRef="/careers"
                        closeNavigate={closeNavigate}
                        />
                    <AdditionalSource
                        sourceCategory="Careers"
                        description="We're always looking to grow our family of passionate, skilled professionals. Ready to join a winning team?"
                        buttonText="Employee Benefits ›"
                        buttonRef="/about-us#careers"
                        closeNavigate={closeNavigate}
                        />
                </div>
            }
        </div>
    )
}